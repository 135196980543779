import React from "react";
import PureModal from "react-pure-modal";
import "react-pure-modal/dist/react-pure-modal.min.css";

function Modal({ icon, button, title, children, onOustSideClick, isOpen }) {
  return (
    <PureModal
      footer={button}
      isOpen={isOpen}
      onClose={onOustSideClick}
      className="bg-white rounded-lg py-[3rem] px-[2rem] min-w-[40rem] w-full  relative z-[999] shadow-2xl flex flex-col justify-center items-center gap-4 mx-6"
    >
      {icon && (
        <img
          src={icon}
          alt="modalIcon"
          width={20}
          height={20}
          className="flex justify-start items-start mx-auto"
        />
      )}

      <h3 className=" text-xl text-black font-semibold text-center">{title}</h3>
      <div className=" ">
        <p className="text-base leading-relaxed text-gray-500 text-center">
          {children}
        </p>
      </div>
      <div className="mt-2">{button}</div>
    </PureModal>
  );
}

export default Modal;
