import React, { useState } from "react";
import DashBoardCard from "../../component/common/Cards/dashBoardCard";
import DonutChart from "../../component/common/Charts/DonutChart";
import BarChart from "../../component/common/Charts/BarChart";
import PieChart from "../../component/common/Charts/PieChart";
import AreaChart from "../../component/common/Charts/AreaChart";
import { useEffect } from "react";
import { useFetch } from "../../hooks/useFetch";
import DateTimePicker from "react-date-picker";
import { getCalculateDate } from "../../utils/getcategory";
import dayjs from "dayjs";
let totalValueOfScope1 = 0;
let totalValueOfScope2 = 0;
let totalValueOfScope3 = 0;
function DashBoard() {
  const [summaryData, setSummaryData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [calculateDataRange, setCalculateDateRange] = useState();
  const [hoverChart, setHoverChart] = useState();
  const [impactScope, setImpactScope] = useState();
  const [avoidEmissionScope, setAvoidEmissionScope] = useState();
  const [offsetsScope, setOffsetsScope] = useState();
  useEffect(() => {
    const FetchData = async () => {
      setIsLoading(true);
      const response = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `${
            calculateDataRange
              ? `/api/Dashboard/Summary?startDate=${calculateDataRange[0]}&endDate=${calculateDataRange[1]}`
              : "/api/Dashboard/Summary?startDate"
          }`,
        method: "GET",
      });
      setSummaryData(response);
      setIsLoading(false);
    };
    FetchData();
  }, [calculateDataRange]);
  useEffect(() => {
    if (
      Array.isArray(summaryData?.scope1AverageEmissionsSummaries) &&
      summaryData?.scope1AverageEmissionsSummaries?.length > 0
    ) {
      summaryData?.scope1AverageEmissionsSummaries.forEach(
        (el) => (totalValueOfScope1 += el?.calculatedEmission)
      );
    }
    if (
      Array.isArray(summaryData?.scope2AverageEmissionsSummaries) &&
      summaryData?.scope2AverageEmissionsSummaries?.length > 0
    ) {
      summaryData?.scope2AverageEmissionsSummaries.forEach(
        (el) => (totalValueOfScope2 += el?.calculatedEmission)
      );
    }
    if (
      Array.isArray(summaryData?.scope3SpendBasedEmissionsSummaries) &&
      summaryData?.scope3SpendBasedEmissionsSummaries?.length > 0
    ) {
      summaryData?.scope3SpendBasedEmissionsSummaries.forEach(
        (el) => (totalValueOfScope3 += el?.calculatedEmission)
      );
    }
  }, [summaryData]);
  return (
    <>
      {isLoading ? (
        <div className="relative h-screen w-full justify-center items-center bg-white/40">
          <div className="absolute top-[45%] left-[45%] h-12 w-12 animate-spin rounded-full border-[3px] border-solid border-green-400 border-t-transparent"></div>
          <div className="absolute top-[46%] left-[45.60%] h-8 w-8 animate-reverse-spin rounded-full border-[3px] border-solid border-black border-t-transparent"></div>
        </div>
      ) : (
        <div className="p-5">
          <div className="flex justify-start items-center gap-2 mb-5 max-w-[35%]">
            <label
              htmlFor=""
              className="text-nowrap text-gray-500 font-semibold text-[20px]"
            >
              select date:
            </label>
            <DateTimePicker
              className="custom-calendar shadow-xl py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
              dayPlaceholder="dd"
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
              calendarClassName=""
              selectRange
              onChange={(value) => {
                if (value) {
                  setCalculateDateRange(
                    value.map((date) => dayjs(date).toISOString())
                  );
                } else setCalculateDateRange([]);
              }}
              format={"dd/MM/yyyy"}
              value={calculateDataRange}
              calendarIcon={
                <img
                  src="/assets/calendar.png"
                  alt="calenderIcon"
                  width={15}
                  height={15}
                />
              }
              required
            />
          </div>
          <div className="grid grid-cols-2 xl:grid-cols-3 gap-5 ">
            <DashBoardCard
              data={
                totalValueOfScope1 + totalValueOfScope2 + totalValueOfScope3
              }
              name={"Carbon Footprint"}
              lastYearData={
                summaryData?.lastYearScope1AverageEmissions +
                summaryData?.lastYearScope2AverageEmissions +
                summaryData?.lastYearScope3SpendBasedEmissions
              }
            />
            <DashBoardCard
              data={getCalculateDate(summaryData?.avoidedEmissionsSummaries)}
              name={"Emissions Avoided"}
              lastYearData={summaryData?.lastYearAvoidedEmissions}
            />
            <DashBoardCard
              data={getCalculateDate(summaryData?.offsetEmissionsSummaries)}
              name={"Offset Emissions"}
              lastYearData={summaryData?.lastYearOffsetEmissions}
            />
            <DashBoardCard
              data={getCalculateDate(
                summaryData?.scope1AverageEmissionsSummaries
              )}
              name={"Scope 1 Emissions"}
              lastYearData={summaryData?.lastYearScope1AverageEmissions}
            />
            <DashBoardCard
              data={getCalculateDate(
                summaryData?.scope2AverageEmissionsSummaries
              )}
              name={"Scope 2 Emissions"}
              lastYearData={summaryData?.lastYearScope2AverageEmissions}
            />
            <DashBoardCard
              data={getCalculateDate(
                summaryData?.scope3AverageEmissionsSummaries
              )}
              name={"Scope 3 Emissions"}
              lastYearData={summaryData?.lastYearScope3SpendBasedEmissions}
            />
          </div>
          <div className="flex flex-col">
            <div className=" flex flex-col mxl:flex-row justify-between gap-6  mt-6 ">
              <div
                className={`p-5 max-w-full lg:max-w-full mxl:max-w-[370px] bg-white rounded-xl bg-clip-border ${
                  hoverChart === "reduction" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("reduction")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <PieChart
                  data1={
                    totalValueOfScope1 + totalValueOfScope2 + totalValueOfScope3
                  }
                  data2={getCalculateDate(
                    summaryData?.avoidedEmissionsSummaries
                  )}
                  data3={getCalculateDate(
                    summaryData?.offsetEmissionsSummaries
                  )}
                />
              </div>
              <div
                className={`p-5 w-full 2xl:w-[780px] bg-white  rounded-2xl bg-clip-border opacity-10 -z-50 ${
                  hoverChart === "reduction" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("reduction")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <AreaChart />
              </div>
            </div>
            <div className=" flex flex-col mxl:flex-row justify-between gap-6  mt-6 ">
              <div
                className={`p-5 max-w-full lg:max-w-full mxl:max-w-[370px] bg-white rounded-xl bg-clip-border ${
                  hoverChart === "scope" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("scope")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <DonutChart
                  scope1={totalValueOfScope1}
                  scope2={totalValueOfScope2}
                  scope3={totalValueOfScope3}
                  title="CO2e IMPACT BY SCOPE"
                  _onChange={setImpactScope}
                />
              </div>
              <div
                className={`p-5 w-full 2xl:w-[780px] bg-white  rounded-2xl bg-clip-border ${
                  hoverChart === "scope" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("scope")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <BarChart
                  height={impactScope === "Scope 3" ? 500 : 300}
                  scope={impactScope}
                  summaryData={summaryData}
                  title="CO2e IMPACT BY CATEGORY"
                />
              </div>
            </div>
            <div className=" flex flex-col mxl:flex-row justify-between gap-6  mt-6 ">
              <div
                className={`p-5 max-w-full lg:max-w-full mxl:max-w-[370px] bg-white rounded-2xl bg-clip-border ${
                  hoverChart === "avoided" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("avoided")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <DonutChart
                  scope1={getCalculateDate(
                    summaryData?.avoidedEmissionsSummaries,
                    1
                  )}
                  scope2={getCalculateDate(
                    summaryData?.avoidedEmissionsSummaries,
                    2
                  )}
                  scope3={getCalculateDate(
                    summaryData?.avoidedEmissionsSummaries,
                    3
                  )}
                  title="Avoid Emission"
                  _onChange={setAvoidEmissionScope}
                />
              </div>
              <div
                className={`p-5 w-full 2xl:w-[780px] bg-white rounded-2xl bg-clip-border ${
                  hoverChart === "avoided" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("avoided")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <BarChart
                  className=""
                  scope={avoidEmissionScope}
                  summaryData={summaryData?.avoidedEmissionsSummaries}
                  title="CO2e REDUCTION BY CATEGORY"
                />
              </div>
            </div>
            <div className=" flex flex-col mxl:flex-row justify-between gap-6  mt-6 ">
              <div
                className={`p-5 max-w-full lg:max-w-full mxl:max-w-[370px] rounded-2xl bg-white bg-clip-border ${
                  hoverChart === "offsets" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("offsets")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <DonutChart
                  scope1={getCalculateDate(
                    summaryData?.offsetEmissionsSummaries,
                    1
                  )}
                  scope2={getCalculateDate(
                    summaryData?.offsetEmissionsSummaries,
                    2
                  )}
                  scope3={getCalculateDate(
                    summaryData?.offsetEmissionsSummaries,
                    3
                  )}
                  title="CO2e Offsets by Emission"
                  _onChange={setOffsetsScope}
                />
              </div>
              <div
                className={`p-5 w-full 2xl:w-[780px] bg-white rounded-2xl bg-clip-border ${
                  hoverChart === "offsets" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("offsets")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <BarChart
                  className=""
                  scope={offsetsScope}
                  summaryData={summaryData?.offsetEmissionsSummaries}
                  title="CO2e OFFSETS BY CATEGORY"
                />
              </div>
            </div>
            <div className=" flex flex-col mxl:flex-row justify-between gap-6  mt-6 ">
              <div
                className={`p-5 w-full 2xl:w-full bg-white rounded-2xl bg-clip-border ${
                  hoverChart === "offsets" ? "shadow-2xl" : "shadow-md"
                }`}
                onMouseEnter={() => setHoverChart("offsets")}
                onMouseLeave={() => setHoverChart(null)}
              >
                <BarChart
                  height={500}
                  scope={offsetsScope}
                  summaryData={summaryData?.offsetEmissionsSummaries}
                  title="CO2e OFFSETS BY Source"
                  defaultScope={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DashBoard;
