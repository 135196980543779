import React from "react";

function PageNotFound() {
  return (
    <div className="max-w-4xl mt-20 mx-auto text-center">
      <h1
        class="text-4xl sm:text-5xl font-bold text-green-500 mb-6 aos-init aos-animate"
        data-aos="flip-up"
      >
        Page Not Found
      </h1>
      <p
        class="text-lg mb-10 font-medium aos-init aos-animate"
        data-aos="zoom-in"
      >
        Sorry, we can't find that page...
      </p>
      <div class="flex justify-center aos-init aos-animate" data-aos="fade-up">
        <div class="w-16 h-1 bg-green-500"></div>
      </div>
      <div class="flex justify-center aos-init aos-animate" data-aos="zoom-out">
        <img src="/assets/404.gif" alt="Coming Soon" class="w-1/2" />
      </div>
      <a href="/">
        <div className="flex flex-row justify-center items-center gap-2">
          <img
            src="/assets/backArrow.png"
            alt="backToHome"
            width={24}
            height={24}
          />
          <p class="text-lg font-normal text-justify my-[20px] text-green-500 mb-6 aos-init aos-animate">
            Back to Home
          </p>
        </div>
      </a>
    </div>
  );
}

export default PageNotFound;
