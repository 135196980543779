import React from "react";
import "ka-table/style.css";
import dayjs from "dayjs";
import { Table } from "ka-table";

function TableComponent({ rowData, columns, onDelete }) {
  const modifyData = rowData.map((mod) => ({
    ...mod,
    activity: mod?.activity?.name ?? mod?.activity ?? "",
    category: mod?.category?.name ?? mod?.category ?? "",
    categorySelected:
      mod?.categorySelected?.name ?? mod?.categorySelected ?? "",
    subActivity: mod?.subActivity?.name ?? mod?.subActivity ?? "",
    activityCategory:
      mod?.activityCategory?.name ?? mod?.activityCategory ?? "",
    activitySubCategory:
      mod?.activitySubCategory?.name ?? mod?.activitySubCategory ?? "",
    type: mod?.type?.name ?? mod?.type ?? "",
    subType: mod?.subType?.name ?? mod?.subType ?? "",
    unit: mod?.unit?.unit ?? mod?.unit ?? "",
    isMonthly: mod?.isMonthly ?? "",
    region: mod?.region?.country ?? mod?.region ?? "",
  }));
  const DeleteRow = ({ dispatch, rowKeyValue }) => {
    return (
      <img
        src="/assets/deleteIcon.png"
        className="delete-row-column-button w-5 h-5 cursor-pointer"
        onClick={() => onDelete(rowKeyValue)}
        alt="deleteIcon"
      />
    );
  };
  return (
    <Table
      columns={columns}
      data={modifyData}
      rowKeyField={"id"}
      childComponents={{
        cellText: {
          content: (props) => {
            switch (props.column.key) {
              case "delete":
                return <DeleteRow {...props} />;
              case "date":
                return dayjs(props.value).format("DD/MM/YYYY");
              case "year":
                return dayjs(props.value).format("MM/YYYY");

              default:
                return;
            }
          },
        },
      }}
    />
  );
}

export default TableComponent;
