import React from "react";
import ReactApexChart from "react-apexcharts";

function DonutChart({ scope1, scope2, scope3, title, _onChange }) {
  const CHART_DATA = [scope1 ?? 0, scope2 ?? 0, scope3 ?? 0];
  const PieOptions = {
    labels: ["Scope 1", "Scope 2", "Scope 3"],
    legend: {
      position: "bottom",
      itemMargin: { vertical: 8 },
    },
    colors: ["#8472E1", "#A5F3FF", "#D0AEFF"],
    chart: {
      events: {
        click: (event, chartContext, config) => {
          _onChange(config.config.labels?.[config?.dataPointIndex]);
        },
      },
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
      lineCap: "round", // This makes the endpoints rounded
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#A5F3FF", "#D0AEFF", "#8472E1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val.toFixed(2) + "%";
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              color: "#263238",
            },
            value: {
              show: true,
              fontSize: "16px",
              color: "#263238",
              formatter: function (val) {
                return Number(val / 1000)?.toFixed(2) + "K";
              },
            },
            total: {
              show: true,
              label: "Total",
              color: "#263238",
              formatter: function (w) {
                return w.globals.seriesTotals
                  .reduce((a, b) => a + b, 0)
                  .toFixed(2);
              },
            },
          },
        },
      },
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "14px",
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2) + "%";
        },
      },
    },
    title: {
      text: title,
      align: "left",
      margin: 20,
      offsetX: 10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: "Helvetica, Arial, sans-serif",
        color: "#263238",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      type="donut"
      series={CHART_DATA}
      options={PieOptions}
      height={400}
    />
  );
}

export default DonutChart;
