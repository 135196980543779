import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const SideBar = () => {
  const { user, logout, loginWithRedirect } = useAuth0();
  const [dateState, setDateState] = useState(new Date());
  const url = new URL(window.location.href);
  const [activeTab, setActiveTab] = useState(url.pathname);
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (
      url.pathname === "/avoided-emission" ||
      url.pathname === "/offsets-emission"
    ) {
      setExpand(true);
    }
  }, [url.pathname]);

  useEffect(() => {
    setInterval(() => {
      setDateState(new Date());
    }, 1000);
  }, []);

  const handleLogoClick = () => {
    window.location.href = "https://www.capcarbontech.com";
  };

  return (
    <div className=" h-full max-h-full pt-5 pb-8 px-6 rounded-r-xl border-r-[1px] border-[#6f866e] flex flex-col justify-between shadow-4xl w-[30%] lg:w-[21%] xl:w-[19%] fixed bg-[#ebfef7]">
      <div className="border-b-[2px] border-b-black text-center pb-6 -mx-6">
        <h2 className="text-[22px] font-bold text-gray-900">CarbMetrics</h2>
        {user ? (
          <img
            src={user?.picture}
            alt="logo"
            width={60}
            height={60}
            className="rounded-full mx-auto mt-[20px] mb-[10px]"
          />
        ) : (
          <div className="mt-[20px] mb-[10px]">
            <div
              className="text-xl font-normal shadow-lg text-green-500 cursor-pointer py-2 px-8 border-[1px] border-green-500 rounded-lg  hover:bg-green-500 hover:text-white transition ease-in-out duration-300 inline-flex"
              onClick={loginWithRedirect}
            >
              Login
            </div>
          </div>
        )}
        <h5 className="font-semibold text-base xl:text-lg text-gray-900">
          {/* if login show company name */}
          Dummy Company Pvt Ltd
        </h5>
        <div>
          <span className="text-sm text-[#4d4d4d]">
            {dateState.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              second: "2-digit",
              hour12: true,
            })}
          </span>
          <span className="text-[#4d4d4d] px-1">|</span>
          <span className="text-sm text-[#4d4d4d]">
            {dayjs(dateState).format("MMM DD YYYY")}
          </span>
        </div>
      </div>
      <div>
        <ul className="flex flex-col pt-3">
          <li
            className={`flex justify-between p-1 items-center cursor-pointer ${
              activeTab === "/"
                ? "rounded-lg shadow-xl bg-green-500 !text-white"
                : ""
            }`}
            onClick={() => setActiveTab("/")}
          >
            <a className="flex items-center w-full" href="/">
              <img
                src="/assets/homeIcon.png"
                alt="homeIcon"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/" ? "text-white" : "text-zinc-400"
                }`}
              >
                Home
              </div>
            </a>
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "/dashboard"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("/dashboard")}
          >
            <a href="/dashboard" className="flex items-center w-full">
              <img
                src="/assets/dashBoardIcon.png"
                alt="emissionIcon"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/dashboard" ? "text-white" : "text-zinc-400"
                }`}
              >
                Dashboard
              </div>
            </a>
          </li>
          <li className={`flex p-1 flex-col gap-2 cursor-pointer`}>
            <div
              className="flex items-center w-full"
              onClick={() => setExpand(!expand)}
            >
              <img
                src="/assets/dashBoardIcon.png"
                alt="emissionIcon"
                width={20}
                height={20}
              />
              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  expand ? "text-black" : "text-zinc-400"
                }`}
              >
                Avoided & Offsets
              </div>
            </div>
            {expand && (
              <>
                <div
                  className={` ml-4 flex p-1 flex-col cursor-pointer ${
                    activeTab === "/avoided-emission"
                      ? "rounded-lg shadow-xl bg-green-500 "
                      : ""
                  }`}
                >
                  <a
                    href="/avoided-emission"
                    className="flex items-center gap-1 w-full"
                  >
                    <img
                      src="/assets/dashBoardIcon.png"
                      alt="emissionIcon"
                      width={20}
                      height={20}
                    />
                    <div
                      className={`text-base xl:text-md hover:opacity-70  ${
                        activeTab === "/avoided-emission"
                          ? "text-white"
                          : "text-zinc-400"
                      }`}
                    >
                      Avoided Emission
                    </div>
                  </a>
                </div>
                <div
                  className={` ml-4 flex p-1 flex-col cursor-pointer ${
                    activeTab === "/offsets-emission"
                      ? "rounded-lg shadow-xl bg-green-500 "
                      : ""
                  }`}
                >
                  <a
                    href="/offsets-emission "
                    className="flex items-center gap-1 w-full"
                  >
                    <img
                      src="/assets/dashBoardIcon.png"
                      alt="emissionIcon"
                      width={20}
                      height={20}
                    />
                    <div
                      className={`text-base xl:text-md hover:opacity-70  ${
                        activeTab === "/offsets-emission"
                          ? "text-white"
                          : "text-zinc-400"
                      }`}
                    >
                      Offsets Emission
                    </div>
                  </a>
                </div>
              </>
            )}
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "/emissioncalculate"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("/emissionCalculate")}
          >
            <a href="/emissioncalculate" className="flex items-center w-full">
              <img
                src="/assets/emissionIcon.png"
                alt="emissionIcon"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/emissioncalculate"
                    ? "text-white"
                    : "text-zinc-400"
                }`}
              >
                Calculate Emission
              </div>
            </a>
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "/reduce-emission"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("/reduce-emission")}
          >
            <a href="/reduce-emission" className="flex items-center w-full">
              <img
                src="/assets/reduceIcon.png"
                alt="reduceIcon"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/reduce-emission"
                    ? "text-white"
                    : "text-zinc-400"
                }`}
              >
                Reduce Emission
              </div>
            </a>
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "/carbon-offsets"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("/carbon-offsets")}
          >
            <a href="/carbon-offsets" className="flex items-center w-full">
              <img
                src="/assets/carbonIcon.png"
                alt="carbone"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/carbon-offsets"
                    ? "text-white"
                    : "text-zinc-400"
                }`}
              >
                Carbon Offsets
              </div>
            </a>
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "/reports"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("/reports")}
          >
            <a href="/reports" className="flex items-center w-full">
              <div>
                <img
                  src="/assets/reportIcon.png"
                  alt="reportIcon"
                  width={20}
                  height={20}
                />
              </div>
              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "/reports" ? "text-white" : "text-zinc-400"
                }`}
              >
                Reports
              </div>
            </a>
          </li>
          <li
            className={`flex p-1 items-center cursor-pointer ${
              activeTab === "getExportHelp"
                ? "rounded-lg shadow-xl bg-green-500 "
                : ""
            }`}
            onClick={() => setActiveTab("getExportHelp")}
          >
            <a href="/" className="flex items-center w-full">
              <img
                src="/assets/getExpertsHelpIcon.png"
                alt="getExpertsHelpIcon"
                width={20}
                height={20}
              />

              <div
                className={`text-base xl:text-md hover:opacity-70 pl-3 ${
                  activeTab === "getExportHelp" ? "text-white" : "text-zinc-400"
                }`}
              >
                Get Expert Help
              </div>
            </a>
          </li>
          <li
            className="flex p-1 items-center"
            onClick={() => setActiveTab("signOut")}
          >
            <div className="flex items-center w-full">
              <img
                src="/assets/signinOutIcon.png"
                alt="signinOutIcon"
                width={20}
                height={20}
              />

              <div
                onClick={() =>
                  logout({
                    logoutParams: { returnTo: window.location.origin },
                  })
                }
                className="text-base xl:text-md text-zinc-400 hover:opacity-70 pl-3 cursor-pointer"
              >
                Sign out
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div
        className="mt-auto self-center mb-0 hover:cursor-pointer"
        onClick={handleLogoClick}
      >
        <img
          className=""
          src="/assets/LogoIcon.png"
          alt="BigCo Inc. logo"
          width={200}
          // height={80}
        />
      </div>
    </div>
  );
};

export default SideBar;
