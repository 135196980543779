import React, { useState } from "react";
import DateTimePicker from "react-date-picker";
import Calculation from "../../component/common/Cards/calculationCard";
import TableComponent from "../../component/common/Table/table";
import dayjs from "dayjs";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";

function AvoidedEmission() {
  const [categoryValue, setCategoryValue] = useState([]);
  const [calculateData, setCalculateData] = useState();
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [allCalculatedData, setAllCalculatedData] = useState([]);
  const [isDisableCalendar, setIsDisableCalendar] = useState(true);
  const HandleSelectCategory = (value) => {
    setCalculateData({
      ...calculateData,
      scope: value,
    });
    if (value === "1") {
      setCategoryValue([
        {
          id: 1,
          name: "Stationary Combustion",
        },
        {
          id: 2,
          name: "Fugitive Emissions - Refrigerants",
        },
        {
          id: 3,
          name: "Mobile Sources",
        },
      ]);
    } else if (value === "2") {
      setCategoryValue([
        {
          id: 1,
          name: "Purchased Electricity",
        },
        {
          id: 2,
          name: "Purchased Steam, Heat, and Cooling",
        },
      ]);
    } else {
      setCategoryValue([
        {
          id: 1,
          name: "Purchased Good and services",
        },
        {
          id: 2,
          name: "Capital Goods",
        },
        {
          id: 3,
          name: "Fuel and Energy Related Activities",
        },
        {
          id: 4,
          name: "Transportation and Distribution (T&D)",
        },
        {
          id: 5,
          name: "Waste Generated in operations",
        },
        {
          id: 6,
          name: "Business Travel",
        },
        {
          id: 7,
          name: "Employee Commuting",
        },
        {
          id: 8,
          name: "Leased Assets",
        },
        {
          id: 9,
          name: "Transportation and Distribution (T&D)",
        },
        {
          id: 10,
          name: "Processing of Sold Products",
        },
        {
          id: 11,
          name: "Use of Sold Products",
        },
        {
          id: 12,
          name: "End-of-life Treatment of Sold Products ",
        },
        {
          id: 13,
          name: "Leased Assets",
        },
        {
          id: 14,
          name: "Franchises",
        },
        {
          id: 15,
          name: "Investments",
        },
      ]);
    }
  };
  const HandleAddData = async (event) => {
    event.preventDefault();
    const date = new Date();
    if (calculateData?.id) {
      setAddCalculatedData(calculateData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      setCalculateData(null);
      return;
    }

    let addId = { ...calculateData, id: date.getTime() };

    setAddCalculatedData(addId);
    setAllCalculatedData([...allCalculatedData, addId]);
    setCalculateData(null);
  };

  const columns = [
    { key: "year", title: "Offsets Year" },
    { key: "scope", title: "Scope" },
    { key: "category", title: "Category" },
    { key: "source", title: "Source" },
    { key: "value", title: "Total Emission Avoided" },
  ];

  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };
  const HandelCalculate = async () => {
    const body = allCalculatedData.map((data) => ({
      month: dayjs(data.year).get("month"),
      year: dayjs(data.year).get("year"),
      scopeId: Number(data.scope),
      categoryId: Number(data.category),
      source: data.source,
      totalEminssionOffset: data.value,
    }));
    try {
      const res = await useFetch({
        url: process.env.REACT_APP_API_URL + `/api/Reduction/Offset`,
        method: "POST",
        body: JSON.stringify(body),
      });
      if (res.status === 204) {
        toast.success("Your data has been successfully recorded!");
        allCalculatedData([]);
        setAddCalculatedData(null);
        setCalculateData();
        setCategoryValue();
      } else toast.error("An error occurred! Please try again later or contact the support team. ");
    } catch (error) {
      toast.error("An error occurred! Please try again later or contact the support team. ");
    }
  };
  return (
    <>
      <div className="font-bold capitalize text-center text-xl lg:text-3xl w-full py-5   shadow-sm rounded-b-lg shadow-green-300 px-2">
        Prevent Emissions Before They Happen: Sustainable Actions for a Cleaner
        Tomorrow
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button
            className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold"
            onClick={HandelCalculate}
          >
            Calculate
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 bg-white rounded-lg border-[1px] shadow-lg p-5 items-center">
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Offsets Year
              </label>
              <DateTimePicker
                onClick={() => {
                  isDisableCalendar && setIsDisableCalendar(!isDisableCalendar);
                }}
                disableCalendar={isDisableCalendar}
                className="custom-calendar bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                calendarClassName=""
                view="year"
                value={calculateData?.year}
                format={"MM/yyyy"}
                calendarIcon={
                  <img
                    src="/assets/calendar.png"
                    alt="calenderIcon"
                    width={15}
                    height={15}
                  />
                }
                onClickMonth={(value) => {
                  setIsDisableCalendar(true);
                  setCalculateData({
                    ...calculateData,
                    year: value,
                  });
                }}
              />
            </div>
            <div>
              <label
                htmlFor="activity"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Scope
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.scope}
                onChange={(event) => HandleSelectCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                <option value={1}>scope 1</option>
                <option value={2}>scope 2</option>
                <option value={3}>scope 3</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="activityCategory"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Category
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.category}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    category: event.target.value,
                  })
                }
                required
              >
                <option value="">select</option>
                {categoryValue.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="value"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#4</span>Source
              </label>
              <input
                type="text"
                id="value"
                value={calculateData?.source}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2 h-[45px]"
                placeholder="Enter value"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    source: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="value"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#5</span>Total Emission Offsets
              </label>
              <input
                type="number"
                id="value"
                value={calculateData?.value}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2 h-[45px]"
                placeholder="Enter value"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    value: event.target.value,
                  })
                }
                required
              />
            </div>
            <div></div>

            <div className="self-center">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-5 pb-5">
        {addCalculatedData && (
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        )}
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default AvoidedEmission;
