import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

function Home() {
  const { user } = useAuth0();
  const slideImages = [
    "/assets/slid1.png",
    "/assets/slid2.png",
    "/assets/slid3.png",
    "/assets/slid4.png",
  ];

  return (
    <div className="h-[97vh]">
      <div className="pt-[30px] mx-4 mt-4 xl:pt-[40px] pb-[40px] bg-welcomeBg bg-no-repeat xl:bg-BgSize bg-center rounded-xl shadow-xl">
        <div className="px-10 relative z-[100]">
          <h1 className="text-[40px] xl:text-[50px]  text-white font-bold leading-[130%]">
            Welcome aboard {user?.nickname}!{" "}
          </h1>
          <p className="text-base xl:text-xl  font-bold text-white max-w-[80%] lg:max-w-[50%] w-full ">
            You're now part of CarbMetrics,
          </p>
          <p className="text-base xl:text-xl  font-bold text-white max-w-[80%] lg:max-w-[50%] w-full ">
            your personalized hub for managing
            carbon emissions.
          </p>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row justify-between  px-4 py-5 gap-5 xl:gap-8">
        <div className=" xl:max-w-[600px] rounded-xl  p-5 relative shadow-xl ">
          <h3 className="text-[22px] xl:text-[26px] leading-8 font-bold mb-6 text-green-500">
            Dive right in to track, manage, and reduce your carbon footprint
            with precision.{" "}
          </h3>
          <p className="pb-5 text-base lg:text-xl leading-7 font-bold text-gray-900">
            Gain actionable insights tailored to your business, and chart a
            course towards sustainability success.{" "}
          </p>
          <p className="text-sm lg:text-base font-medium leading-relaxed aos-init aos-animate mb-2">
            Let's make a difference together! Explore our intuitive dashboard to
            visualize your emissions data, set reduction goals, and monitor
            progress in real time. With CarbMetrics, you have the power to drive
            positive change and lead your organization towards a greener future.{" "}
          </p>
          <p className="text-sm lg:text-base font-medium leading-relaxed aos-init aos-animate">
            Start calculating your emissions today and be a catalyst for
            environmental stewardship!
          </p>
          <div className="text-center mt-0 mr-[0px]">
            <div className="mt-8">
              <a
                href="/emissioncalculate"
                className="text-xl font-normal shadow-lg text-green-500  py-4 px-8 border-[1px] border-green-500 rounded-lg  hover:bg-green-500 hover:text-white transition ease-in-out duration-300 inline-flex justify-center items-center"
              >
                Let's Track Your Emission!
              </a>
              {/* <img
                alt="video-arrow"
                loading="lazy"
                width="57"
                height="20"
                decoding="async"
                data-nimg="1"
                src="/assets/video-arrow.svg"
                data-cmp-ab="2"
                data-cmp-info="10"
                className="absolute bottom-[30px] left-[100px] lg:left-[200px] xl:left-[50px] max-w-[20px] w-full h-[20] rotate-[-19deg]"
              /> */}
            </div>
          </div>
        </div>

        <div className="max-w-[300px] lg:max-w-[500px] w-full xl:max-w-[600px] rounded-xl bg-white shadow-xl self-center">
          <Slide scale={0.4} arrows={false} autoplay={true}>
            {slideImages.map((each, index) => (
              <img
                key={index}
                style={{ width: "100%" }}
                src={each}
                alt={each}
                className="rounded-xl flex justify-center items-center border-t-none"
              />
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
}

export default Home;
