/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from "react";
import DateTimePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import Calculation from "../../component/common/Cards/calculationCard";
import TitleDescriptionCard from "../../component/common/Cards/titleDescriptionCard";
import TableComponent from "../../component/common/Table/table";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";
const initialData = {
  category: "",
  activity: "",
  activityCategory: "",
  activitySubCategory: "",
  vendor: "",
  isMonthly: true,
  date: "",
  unit: "",
  value: "",
};
function ScopeOneIndividual() {
  const { method } = useParams();

  const [classCategory, setClassCategory] = useState([]);
  const [activity, setActivity] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [subActivityCategory, setSubActivityCategory] = useState([]);
  const [units, setUnits] = useState([]);
  const HandelAvgClass = async () => {
    const categoryClass = await useFetch({
      url: process.env.REACT_APP_API_URL + "/api/AverageMethod/class/1",
      method: "GET",
    });
    setClassCategory(categoryClass);
  };
  useEffect(() => {
    if (method === "average-data-method") {
      HandelAvgClass();
    }
  }, [method]);

  const [calculateData, setCalculateData] = useState(initialData);
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [allCalculatedData, setAllCalculatedData] = useState([]);

  const HandleSelectCategory = async (category) => {
    setCalculateData({
      ...calculateData,
      category: classCategory.find((item) => item.id === Number(category)),
    });
    try {
      const ActivityData = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/AverageMethod/activity/${category}`,
        method: "GET",
      });
      setActivity(ActivityData);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSelectActivity = async (activityItem) => {
    setCalculateData({
      ...calculateData,
      activity: activity.find((item) => item.id === Number(activityItem)),
    });
    try {
      const ActivityCategoryData = await useFetch({
        url:
          calculateData?.category?.id === 3
            ? process.env.REACT_APP_API_URL +
              `/api/AverageMethod/Scope1/vehicleType/${activityItem}`
            : process.env.REACT_APP_API_URL +
              `/api/AverageMethod/category/${calculateData.category.id}?activityId=${activityItem}`,
        method: "GET",
      });
      calculateData?.category?.id !== 3 &&
        setUnits(
          ActivityCategoryData.map((unit) => ({ id: unit.id, unit: unit.unit }))
        );
      setActivityCategory(ActivityCategoryData);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleActivityCategory = async (ActivityCategory) => {
    setCalculateData({
      ...calculateData,
      activityCategory: activityCategory.find(
        (item) => item.id === Number(ActivityCategory)
      ),
      unit: activityCategory.find(
        (item) => item.id === Number(ActivityCategory)
      ),
    });
    calculateData?.category?.id !== 3 &&
      setUnits(
        activityCategory
          .filter((item) => item.id === Number(ActivityCategory))
          .map((uni) => ({ id: uni.id, unit: uni.unit }))
      );
    try {
      const vehicleFuel = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/AverageMethod/Scope1/vehicleFuel`,
        method: "GET",
      });
      setSubActivityCategory(vehicleFuel);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSelectActivitySubCategory = async (fuelType) => {
    setCalculateData({
      ...calculateData,
      activitySubCategory: subActivityCategory.find(
        (item) => item.id === Number(fuelType)
      ),
    });

    try {
      const unitData = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/AverageMethod/Scope1/FuelComsumptionParams/${calculateData.activity.id}/${calculateData.activityCategory.id}/${fuelType}`,
        method: "GET",
      });
      setUnits(unitData);
    } catch (error) {
      console.log(error);
    }
  };
  const HandelSelectUnit = (unit) => {
    setCalculateData({
      ...calculateData,
      unit: units.find((item) => item.id === Number(unit)),
    });
  };

  const HandleAddData = async (event) => {
    event.preventDefault();
    const date = new Date();
    const modifyData = {
      ...calculateData,
      activity: calculateData?.activity ?? "",
      category: calculateData?.category ?? "",
      categorySelected: calculateData?.categorySelected ?? "",
      activityCategory: calculateData?.activityCategory ?? "",
      activitySubCategory: calculateData?.activitySubCategory ?? "",
      unit: calculateData?.unit ?? "",
      id: calculateData.id ?? date.getTime(),
    };
    if (calculateData?.id) {
      setAddCalculatedData(modifyData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      setCalculateData({
        ...calculateData,
        vendor: "",
        date: "",
        value: "",
        id: null,
      });
      return;
    }

    let addId = { ...calculateData, id: date.getTime() };

    setAddCalculatedData(modifyData);
    setAllCalculatedData([...allCalculatedData, addId]);
    setCalculateData({
      ...calculateData,
      vendor: "",
      date: "",
      value: "",
      id: null,
    });
  };

  const HandelCalculate = async () => {
    const StationaryCombustionBody = allCalculatedData
      .filter((fil) => fil.category.id === 1)
      .map((itemData) => ({
        scopeId: 1,
        classId: itemData.category.id,
        activityId: itemData.activity.id,
        paramId: Number(itemData.unit.id),
        categoryId: itemData.activityCategory.id,
        vehicleId: -406,
        vehicleFuelId: -406,
        unit: itemData.unit.unit,
        vendor: itemData.vendor,
        invTrnzDate: itemData.date,
        isMontlhlyData: itemData.isMonthly === "false" ? false : true,
        enteredValue: Number(itemData.value),
        calcMethodId: 1,
      }));
    const RefreigerantsBody = allCalculatedData
      .filter((fil) => fil.category.id === 2)
      .map((itemData) => ({
        scopeId: 1,
        classId: itemData.category.id,
        activityId: itemData.activity.id,
        paramId: Number(itemData.unit.id),
        categoryId: itemData.activityCategory.id,
        vehicleId: -406,
        vehicleFuelId: -406,
        unit: itemData.unit.unit,
        vendor: itemData.vendor,
        invTrnzDate: itemData.date,
        isMontlhlyData: itemData.isMonthly === "false" ? false : true,
        enteredValue: Number(itemData.value),
        calcMethodId: 1,
      }));
    const MobileSourcesBody = allCalculatedData
      .filter((fil) => fil.category.id === 3)
      .map((itemData) => ({
        scopeId: 1,
        classId: itemData.category.id,
        activityId: itemData.activity.id,
        paramId: Number(itemData.unit.id),
        categoryId: itemData.activityCategory.id,
        vehicleId: itemData.activityCategory.id,
        vehicleFuelId: itemData.activitySubCategory.id,
        unit: itemData.unit.unit,
        vendor: itemData.vendor,
        invTrnzDate: itemData.date,
        isMontlhlyData: itemData.isMonthly === "false" ? false : true,
        enteredValue: Number(itemData.value),
        calcMethodId: 1,
      }));
    if (StationaryCombustionBody?.length > 0) {
      try {
        const res = await useFetch({
          url:
            process.env.REACT_APP_API_URL +
            `/api/AverageMethod/Scope1/StationaryCombustion`,
          method: "POST",
          body: JSON.stringify(StationaryCombustionBody),
        });
        if (res.status === 204) {
          toast.success("Your data has been successfully recorded!");
          allCalculatedData([]);
          setAddCalculatedData(null);
          setCalculateData();
        } else toast.error("An error occurred! Please try again later or contact the support team. ");
      } catch (error) {}
    }
    if (RefreigerantsBody?.length > 0) {
      try {
        const res = await useFetch({
          url:
            process.env.REACT_APP_API_URL +
            `/api/AverageMethod/Scope1/Refreigerants`,
          method: "POST",
          body: JSON.stringify(RefreigerantsBody),
        });
        if (res.status === 204) {
          toast.success("Your data has been successfully recorded!");
          allCalculatedData([]);
          setAddCalculatedData(null);
          setCalculateData();
        } else toast.error("An error occurred! Please try again later or contact the support team. ");
      } catch (error) {}
    }
    if (MobileSourcesBody?.length > 0) {
      try {
        const res = await useFetch({
          url:
            process.env.REACT_APP_API_URL +
            `/api/AverageMethod/Scope1/MobileSources`,
          method: "POST",
          body: JSON.stringify(MobileSourcesBody),
        });
        if (res.status === 204) {
          toast.success("Your data has been successfully recorded!");
          allCalculatedData([]);
          setAddCalculatedData(null);
          setCalculateData();
        } else toast.error("An error occurred! Please try again later or contact the support team. ");
      } catch (error) {}
    }
  };

  const columns = [
    { key: "category", title: "Category" },
    { key: "activity", title: "Activity" },
    { key: "activityCategory", title: "Activity Category" },
    { key: "activitySubCategory", title: "Activity Subcategory" },
    { key: "vendor", title: "Vendor" },
    { key: "isMonthly", title: "Is Monthly" },
    { key: "date", title: "Date" },
    { key: "unit", title: "Unit" },
    { key: "value", title: "Value" },
    { key: "delete", title: "Action" },
  ];
  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };
  return (
    <>
      <div className="font-bold capitalize text-center text-xl lg:text-3xl w-full py-5   shadow-sm rounded-b-lg shadow-green-300 px-2">
        {`Scope 1 | ${method.replaceAll("-", " ")} | Individual Records`}
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button
            className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold"
            onClick={HandelCalculate}
          >
            Calculate
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 bg-white rounded-lg border-[1px] shadow-lg p-5 items-center">
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Category
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.category?.id}
                onChange={(event) => HandleSelectCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {classCategory?.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activity"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Activity
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.activity?.id}
                onChange={(event) => HandleSelectActivity(event.target.value)}
                required
              >
                <option value="">select</option>
                {activity.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activityCategory"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Activity Category
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.activityCategory?.id}
                onChange={(event) => HandleActivityCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {activityCategory.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item?.unit ? `${item.name} - ${item?.unit}` : item?.name}
                  </option>
                ))}
              </select>
            </div>
            {calculateData.category.id === 3 && (
              <div>
                <label
                  htmlFor="fuelType"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  <span className="pr-1">#4</span>Activity Sub-Category
                </label>
                <select
                  className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                  value={calculateData?.fuelType?.id}
                  onChange={(event) =>
                    HandleSelectActivitySubCategory(event.target.value)
                  }
                  required
                >
                  <option value="">select</option>
                  {subActivityCategory.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <label
                htmlFor="vendor"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#5</span>Vendor
              </label>
              <input
                type="text"
                id="vendor"
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2 leading-[180%] h-[45px]"
                placeholder="Enter Vendor"
                value={calculateData?.vendor}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    vendor: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="isMonth"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#6</span>Is Monthly
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.isMonthly}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    isMonthly: event.target.value,
                  })
                }
                required
              >
                <option value="">select</option>
                <option value={true}>True</option>

                <option value={false}>False</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#7</span> Date
              </label>
              <DateTimePicker
                className="custom-calendar bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                dayPlaceholder="dd"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                calendarClassName=""
                view="month"
                onChange={(value) =>
                  setCalculateData({
                    ...calculateData,
                    date: value,
                  })
                }
                format={"dd/MM/yyyy"}
                value={calculateData?.date}
                calendarIcon={
                  <img
                    src="/assets/calendar.png"
                    alt="calenderIcon"
                    width={15}
                    height={15}
                  />
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="unit"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#8</span>Unit
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.unit?.id}
                onChange={(event) => HandelSelectUnit(event.target.value)}
                required
              >
                <option value="">select</option>
                {units.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.unit}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="value"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#9</span>Enter value
              </label>
              <input
                type="number"
                id="value"
                value={calculateData?.value}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2 h-[45px]"
                placeholder="Enter value"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    value: event.target.value,
                  })
                }
                required
              />
            </div>

            {calculateData.category.id !== 3 && <div></div>}
            <div className="self-center">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-5 pb-5">
        {addCalculatedData && (
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        )}
      </div>
      <div className="mx-5 border-[1px] border-green-600 bg-white rounded-lg shadow-lg px-5">
        <TitleDescriptionCard data={titleDesc} />
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default ScopeOneIndividual;
const titleDesc = {
  rows: [
    {
      title: <h4 className="font-bold text-xl">More Details</h4>,
      content: (
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-6 pb-6 pr-2 mt-4">
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/StationaryCombustion.png"
                alt="StationaryCombustion"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">Stationary Combustion</p>
            </div>
            <p>
              Direct emissions from the combustion of fuels in stationary
              sources like power plants or industrial facilities.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/MobileSources.png"
                alt="MobileSources"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">Mobile Sources</p>
            </div>
            <p>
              Direct emissions from transportation sources such as cars, trucks,
              ships, and airplanes.
            </p>
          </div>
          <div className="bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Fugitive Emissions - Refrigeration
              </p>
            </div>
            <p>
              Indirect emissions from leaks or releases of refrigerants used in
              cooling systems like air conditioners or refrigeration units.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Fugitive Emissions - Fire Suppression
              </p>
            </div>
            <p>
              Indirect emissions resulting from the unintentional release of
              gases used in fire suppression systems, such as halons or
              hydrofluorocarbons (HFCs).
            </p>
          </div>
        </div>
      ),
    },
  ],
};
