import React, { useState } from "react";
import DateTimePicker from "react-date-picker";
import Calculation from "../../component/common/Cards/calculationCard";
import TitleDescriptionCard from "../../component/common/Cards/titleDescriptionCard";
import TableComponent from "../../component/common/Table/table";
import dayjs from "dayjs";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";

function OffsetsEmission() {
  const [categoryValue, setCategoryValue] = useState([]);
  const [calculateData, setCalculateData] = useState();
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [allCalculatedData, setAllCalculatedData] = useState([]);
  const [isDisableCalendar, setIsDisableCalendar] = useState(true);
  let sourceValue = [
    "Reforestation/Afforestation",
    "Forest Conservation",
    "Blue Carbon",
    "Regenerative Agriculture",
    "Solar Energy",
    "Wind Energy",
    "Geothermal Energy",
    "Hydropower",
    "Energy Efficiency",
    "Methane Capture",
    "Direct Air Capture",
    "Others",
  ];
  const HandleSelectCategory = (value) => {
    setCalculateData({
      ...calculateData,
      scope: value,
    });
    if (value === "1") {
      setCategoryValue([
        {
          id: 1,
          name: "Stationary Combustion",
        },
        {
          id: 2,
          name: "Fugitive Emissions - Refrigerants",
        },
        {
          id: 3,
          name: "Mobile Sources",
        },
      ]);
    } else if (value === "2") {
      setCategoryValue([
        {
          id: 1,
          name: "Purchased Electricity",
        },
        {
          id: 2,
          name: "Purchased Steam, Heat, and Cooling",
        },
      ]);
    } else {
      setCategoryValue([
        {
          id: 1,
          name: "Purchased Good and services",
        },
        {
          id: 2,
          name: "Capital Goods",
        },
        {
          id: 3,
          name: "Fuel and Energy Related Activities",
        },
        {
          id: 4,
          name: "Transportation and Distribution (T&D)",
        },
        {
          id: 5,
          name: "Waste Generated in operations",
        },
        {
          id: 6,
          name: "Business Travel",
        },
        {
          id: 7,
          name: "Employee Commuting",
        },
        {
          id: 8,
          name: "Leased Assets",
        },
        {
          id: 9,
          name: "Transportation and Distribution (T&D)",
        },
        {
          id: 10,
          name: "Processing of Sold Products",
        },
        {
          id: 11,
          name: "Use of Sold Products",
        },
        {
          id: 12,
          name: "End-of-life Treatment of Sold Products ",
        },
        {
          id: 13,
          name: "Leased Assets",
        },
        {
          id: 14,
          name: "Franchises",
        },
        {
          id: 15,
          name: "Investments",
        },
      ]);
    }
  };
  const HandleAddData = async (event) => {
    event.preventDefault();
    const date = new Date();
    if (calculateData?.id) {
      setAddCalculatedData(calculateData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      setCalculateData(null);
      return;
    }

    let addId = { ...calculateData, id: date.getTime() };

    setAddCalculatedData(addId);
    setAllCalculatedData([...allCalculatedData, addId]);
    setCalculateData(null);
  };
  const columns = [
    { key: "year", title: "Offsets Year" },
    { key: "scope", title: "Scope" },
    { key: "category", title: "Category" },
    { key: "source", title: "Source" },
    { key: "value", title: "Total Emission Offsets" },
  ];
  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };
  const HandelCalculate = async () => {
    const body = allCalculatedData.map((data) => ({
      month: dayjs(data.year).get("month"),
      year: dayjs(data.year).get("year"),
      scopeId: Number(data.scope),
      categoryId: Number(data.category),
      source: data.source,
      totalEminssionOffset: data.value,
    }));
    try {
      const res = await useFetch({
        url: process.env.REACT_APP_API_URL + `/api/Reduction/Offset`,
        method: "POST",
        body: JSON.stringify(body),
      });
      if (res.status === 204) {
        toast.success("Your data has been successfully recorded!");
        allCalculatedData([]);
        setAddCalculatedData(null);
        setCalculateData();
        setCategoryValue();
      } else {toast.error("An error occurred! Please try again later or contact the support team. ");}
    } catch (error) {
      toast.error("An error occurred! Please try again later or contact the support team. ");
    }
  };
  return (
    <>
      <div className="font-bold capitalize text-center text-xl lg:text-3xl w-full py-5   shadow-sm rounded-b-lg shadow-green-300 px-2">
        Offset Your Carbon Footprint: Sustainable Solutions for a Greener Future
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button
            className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold"
            onClick={HandelCalculate}
          >
            Calculate
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 bg-white rounded-lg border-[1px] shadow-lg p-5 items-center">
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Offsets Year
              </label>
              <DateTimePicker
                onClick={() => setIsDisableCalendar(!isDisableCalendar)}
                disableCalendar={isDisableCalendar}
                className="custom-calendar bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                yearPlaceholder="yyyy"
                calendarClassName=""
                value={calculateData?.year}
                view="decade"
                format={"yyyy"}
                calendarIcon={
                  <img
                    src="/assets/calendar.png"
                    alt="calenderIcon"
                    width={15}
                    height={15}
                  />
                }
                onClickYear={(value) => {
                  setIsDisableCalendar(true);
                  setCalculateData({
                    ...calculateData,
                    year: value,
                  });
                }}
              />
            </div>
            <div>
              <label
                htmlFor="activity"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Scope
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.scope}
                onChange={(event) => HandleSelectCategory(event.target.value)}
              >
                <option value="">select</option>
                <option value="1">scope 1</option>
                <option value="2">scope 2</option>
                <option value="3">scope 3</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="activityCategory"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Category
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.category}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    category: event.target.value,
                  })
                }
              >
                <option value="">select</option>
                {categoryValue.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="fuelType"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#4</span>Source
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.source}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    source: event.target.value,
                  })
                }
                required
              >
                <option value="">select</option>
                {sourceValue.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="value"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#9</span>Total Emission Offsets
              </label>
              <input
                type="number"
                id="value"
                value={calculateData?.value}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2 h-[45px]"
                placeholder="Enter value"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    value: event.target.value,
                  })
                }
                required
              />
            </div>
            <div></div>
            <div className="self-center">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-5 pb-5">
        {addCalculatedData && (
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        )}
      </div>
      <div className="mx-5 border-[1px] border-green-600 bg-white rounded-lg shadow-lg px-5">
        <TitleDescriptionCard data={titleDesc} />
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default OffsetsEmission;
const titleDesc = {
  rows: [
    {
      title: <h4 className="font-bold text-xl">More Details</h4>,
      content: (
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-6 pb-6 pr-2 mt-4">
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/StationaryCombustion.png"
                alt="StationaryCombustion"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">
                Reforestation/Afforestation
              </p>
            </div>
            <p>
              Reforestation involves planting trees in areas where forests have
              been cut down or degraded. Afforestation refers to creating new
              forests in areas that were not previously forested. Both processes
              sequester carbon dioxide from the atmosphere as trees grow and
              absorb CO2, storing it in biomass and soil.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/MobileSources.png"
                alt="MobileSources"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Forest Conservation</p>
            </div>
            <p>
              Forest conservation projects focus on protecting existing forests
              from deforestation and degradation. By preventing the loss of
              forests, these projects maintain their role as carbon sinks,
              preserving the carbon stored in trees and soil and preventing CO2
              emissions from deforestation.
            </p>
          </div>
          <div className="bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Blue Carbon</p>
            </div>
            <p>
              Blue carbon projects involve the protection and restoration of
              coastal and marine ecosystems such as mangroves, salt marshes, and
              seagrass meadows. These ecosystems are highly efficient at
              capturing and storing carbon, both in plant biomass and in
              sediments.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Regenerative Agriculture</p>
            </div>
            <p>
              Regenerative agriculture promotes farming and grazing practices
              that restore soil health, enhance biodiversity, and increase
              carbon sequestration. Techniques include crop rotation, cover
              cropping, reduced tillage, and holistic grazing management, which
              improve soil organic matter and capture CO2.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Solar Energy</p>
            </div>
            <p>
              Solar energy projects generate electricity from sunlight using
              photovoltaic cells or solar thermal systems. By displacing fossil
              fuel-based energy, solar energy reduces greenhouse gas emissions
              and helps mitigate climate change.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Wind Energy</p>
            </div>
            <p>
              Wind energy projects harness the power of wind to generate
              electricity using wind turbines. Wind power is a renewable, clean
              energy source that reduces reliance on fossil fuels and lowers
              carbon emissions.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Geothermal Energy</p>
            </div>
            <p>
              Geothermal energy projects utilize heat from the Earth's interior
              to generate electricity or provide direct heating. This renewable
              energy source has a low carbon footprint and can provide a stable
              and reliable energy supply.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Hydropower</p>
            </div>
            <p>
              Hydropower projects generate electricity by harnessing the energy
              of flowing water, typically using dams on rivers. While hydropower
              is a renewable energy source, it must be carefully managed to
              minimize ecological impacts and displacement issues.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Energy Efficiency</p>
            </div>
            <p>
              Energy efficiency projects aim to reduce energy consumption
              through improved technologies and practices. This includes
              upgrading buildings, industrial processes, and transportation
              systems to use less energy, thereby reducing greenhouse gas
              emissions.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Methane Capture</p>
            </div>
            <p>
              Methane capture projects focus on collecting and utilizing methane
              emissions from sources like landfills, agriculture, and wastewater
              treatment plants. Methane is a potent greenhouse gas, and
              capturing it for energy use or flaring significantly reduces its
              impact on climate change.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Direct Air Capture</p>
            </div>
            <p>
              Direct air capture (DAC) projects involve technology that extracts
              CO2 directly from the atmosphere. The captured CO2 can then be
              stored underground or used in various industrial processes,
              effectively reducing the amount of greenhouse gas in the
              atmosphere.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              {/* <img
                src="/assets/FugitiveEmissions.png"
                alt="FugitiveEmissions"
                width={24}
                height={24}
              /> */}
              <p className="font-semibold text-lg">Others</p>
            </div>
            <p>
              This category includes a variety of innovative and emerging carbon
              offset projects that don't fit neatly into the other categories.
              Examples might include biochar production, carbon mineralization,
              enhanced weathering, and sustainable waste management practices
              that reduce emissions and promote carbon sequestration.
            </p>
          </div>
        </div>
      ),
    },
  ],
};
