import dayjs from "dayjs";
import React from "react";

function Calculation({ CalculateData, setCalculateData }) {
  const Data = Object.entries(CalculateData);
  const HandleEditData = (event) => {
    event.preventDefault();
    setCalculateData(CalculateData);
  };
  return (
    <>
      <div className=" p-6 bg-white border border-gray-200 rounded-lg shadow-lg  mb-6">
        <div className="flex justify-end mb-4">
          <button
            onClick={HandleEditData}
            className="py-1 px-6 border-[1px] border-gray-500 text-gray-500 rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold hover:bg-green-500"
          >
            Edit
          </button>
        </div>
        <div className="grid grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {Array.isArray(Data) &&
            Data.filter(([title]) => title !== "id").map(
              ([title, value], index) => (
                <div
                  key={index}
                  className="p-3 border-[1px] rounded-lg bg-green-50"
                >
                  <div className="capitalize text-base font-medium">
                    {title} :
                  </div>
                  <div className="text-sm font-normal pt-1">
                    {title === "date" || title === "year"
                      ? dayjs(value).format("DD/MM/YYYY")
                      : title === "unit"
                      ? value?.unit ?? value
                      : title === "region"
                      ? value?.country ?? value
                      : value?.name ?? value}
                  </div>
                </div>
              )
            )}
        </div>
      </div>
    </>
  );
}

export default Calculation;
