import "./App.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import EmissionCalculate from "./pages/EmissionCalculate";
import ScopeOneIndividual from "./pages/ScopeOneIndiidual";
import ScopeTwoIndividual from "./pages/ScopeTwoIndividual";
import ScopeThreeIndividual from "./pages/ScopeThreeIndividual";
import AllScopeBulk from "./pages/ScopeBulk";
import DataReview from "./pages/DataReview";
import DashBoard from "./pages/DashBoard";
import ReduceEmission from "./pages/ReduceEmission";
import CarbonOffsets from "./pages/CarbonOffsets";
import Reports from "./pages/Reports";
import PageNotFound from "./pages/PageNotFound";
import AvoidedEmission from "./pages/AvoidedEmission";
import OffsetsEmission from "./pages/OffsetsEmission";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/emissioncalculate" element={<EmissionCalculate />} />
        <Route path="/reduce-emission" element={<ReduceEmission />} />
        <Route path="/carbon-offsets" element={<CarbonOffsets />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/avoided-emission" element={<AvoidedEmission />} />
        <Route path="/offsets-emission" element={<OffsetsEmission />} />
        {/* <Route path="/get-export-help" element={<EmissionCalculate />} /> */}
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
        <Route
          path="/scope-one-individual/:method"
          element={<ScopeOneIndividual />}
        />
        <Route
          path="/scope-two-individual/:method"
          element={<ScopeTwoIndividual />}
        />
        <Route
          path="/scope-three-individual/:method"
          element={<ScopeThreeIndividual />}
        />
        <Route
          path="/data-review/:scope/:bulk/:method"
          element={<DataReview />}
        />
        <Route path="/:scope/:bulk/:method" element={<AllScopeBulk />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
