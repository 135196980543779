import React from "react";

function CarbonOffsets() {
  return (
    <div className="max-w-4xl mt-20 mx-auto text-center">
      <h1
        class="text-4xl sm:text-5xl font-bold text-green-500 mb-6 aos-init aos-animate"
        data-aos="flip-up"
      >
        Coming Soon
      </h1>
      <p
        class="text-lg mb-10 font-medium aos-init aos-animate"
        data-aos="zoom-in"
      >
        We're working hard to bring you something amazing. Stay tuned for
        updates!
      </p>
      <div class="flex justify-center aos-init aos-animate" data-aos="fade-up">
        <div class="w-16 h-1 bg-green-500"></div>
      </div>
      <div class="flex justify-center aos-init aos-animate" data-aos="zoom-out">
        <img src="/assets/comingsoon.gif" alt="Coming Soon" class="w-1/2" />
      </div>
    </div>
  );
}

export default CarbonOffsets;
