import React, { useEffect } from "react";
import SideBar from "./sideBar";
import { useAuth0 } from "@auth0/auth0-react";
import cookie from "cookiejs";

function Layout({ children }) {
  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();
  useEffect(() => {
    const fetchAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        cookie.set("access_token", token, 30);
      } catch (e) {
        console.error(e);
      }
    };

    if (isAuthenticated) {
      fetchAccessToken();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return (
      <div className="relative h-screen w-screen justify-center items-center bg-white/40">
        <div className="absolute top-[50%] left-[50%] h-12 w-12 animate-spin rounded-full border-[3px] border-solid border-green-400 border-t-transparent"></div>
        <div className="absolute top-[51.25%] left-[50.50%] h-8 w-8 animate-reverse-spin rounded-full border-[3px] border-solid border-black border-t-transparent"></div>
      </div>
    );
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  if (isAuthenticated) {
    return (
      <div className="hidden md:grid grid-cols-[0.6fr_1.3fr] lg:grid-cols-[0.6fr_2fr] xl:grid-cols-[0.6fr_2.5fr]">
        <div className="">
          <SideBar />
        </div>
        <div className="">{children}</div>
      </div>
    );
  } else {
    return loginWithRedirect();
  }
}
export default Layout;
