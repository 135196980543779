import React from "react";
import { useParams } from "react-router-dom";

import TableComponent from "../../component/common/Table/table";

const columns = [
  { key: "category", title: "CategorySelected" },
  { key: "activity", title: "Activity" },
  { key: "vendor", title: "Vendor" },
  { key: "category", title: "Category" },
  { key: "subActivity", title: "SubActivity" },
  { key: "unit", title: "Unit" },
  { key: "value", title: "Value" },
];

function DataReview({ allCalculatedData }) {
  const { method, scope } = useParams();

  return (
    <>
      {" "}
      <div className="font-bold capitalize text-center text-xl xl:text-3xl w-full py-5 shadow-sm rounded-b-lg shadow-green-300 px-2">
        {`${scope} | ${method.replaceAll("-", " ")} | Bulk Upload`}
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold">
            Data Review
          </button>
        </div>
      </div>
      <div className="mt-6 px-5 pb-5">
        <TableComponent columns={columns} rowData={allCalculatedData} />
      </div>
    </>
  );
}

export default DataReview;
