import React from "react";
import Faq from "react-faq-component";

function TitleDescriptionCard({ data }) {
  const config = {
    animate: true,
    arrowIcon: "V",
    openOnload: 0,
    collapseIcon: <img src="/assets/rottedNextArrowIcon.png" alt="nextIcon" />,
    expandIcon: <img src="/assets/rottedNextArrowIcon.png" alt="nextIcon" />,
  };
  const styles = {
    //  titleTextColor: '#111827',
    titleTextSize: "30px",
    //  rowTitleColor: 'gray',
    rowContentTextSize: "16px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "0px",
  };
  return (
    <Faq
      data={data}
      styles={styles}
      config={config}
      className="faq-row-wrapper"
    />
  );
}

export default TitleDescriptionCard;
