const Scope_1_Category = [
  {
    id: 1,
    text: "Stationary Combustion",
    calculateDate: 0,
  },
  {
    id: 2,
    text: "Fugitive Emissions - Refrigerants",
    calculateDate: 0,
  },
  { id: 3, text: "Mobile Sources", calculateDate: 0 },
];
const Scope_2_Category = [
  {
    id: 1,
    text: "Purchased Heat and Steam",
    calculateDate: 0,
  },
  {
    id: 2,
    text: "Purchased Electricity - India",
    calculateDate: 0,
  },
  {
    id: 3,
    text: "Purchased Electricity - Overseas",
    calculateDate: 0,
  },
];
const Scope_3_Category = [
  {
    id: 1,
    text: "Purchased Good and services",
  },
  {
    id: 2,
    text: "Capital Goods",
  },
  {
    id: 3,
    text: "Fuel and Energy Related Activities",
  },
  {
    id: 4,
    text: "Transportation and Distribution (T&D)",
  },
  {
    id: 5,
    text: "Waste Generated in operations",
  },
  {
    id: 6,
    text: "Business Travel",
  },
  {
    id: 7,
    text: "Employee Commuting",
  },
  {
    id: 8,
    text: "Leased Assets",
  },
  {
    id: 9,
    text: "Transportation and Distribution (T&D)",
  },
  {
    id: 10,
    text: "Processing of Sold Products",
  },
  {
    id: 11,
    text: "Use of Sold Products",
  },
  {
    id: 12,
    text: "End-of-life Treatment of Sold Products ",
  },
  {
    id: 13,
    text: "Leased Assets",
  },
  {
    id: 14,
    text: "Franchises",
  },
  {
    id: 15,
    text: "Investments",
  },
];
const sourceValue = [
  { id: 1, text: "Reforestation/Afforestation" },
  { id: 2, text: "Forest Conservation" },
  { id: 3, text: "Blue Carbon" },
  { id: 4, text: "Regenerative Agriculture" },
  { is: 5, text: "Solar Energy" },
  { id: 6, text: "Wind Energy" },
  { id: 7, text: "Geothermal Energy" },
  { id: 8, text: "Hydropower" },
  { id: 9, text: "Energy Efficiency" },
  { id: 10, text: "Methane Capture" },
  { id: 11, text: "Direct Air Capture" },
  { id: 12, text: "Others" },
];

export function getCategoryData({ scopeID, defaultScope = true, dataValue }) {
  const selectedScopeData = Array.isArray(dataValue)
    ? dataValue?.filter((scope) => scope.scopeId === scopeID)
    : [];
  if (scopeID === 1 && defaultScope) {
    const newData = Scope_1_Category.map((item) => {
      let sumOfCategory = 0;
      selectedScopeData
        .filter((id) => id?.categoryId === item.id)
        .forEach((el) => (sumOfCategory += el?.calculatedEmission));
      return {
        ...item,
        calculateDate: sumOfCategory,
      };
    });
    return newData;
  } else if (scopeID === 2 && defaultScope) {
    const newData = Scope_2_Category.map((item) => {
      let sumOfCategory = 0;
      selectedScopeData
        .filter((id) => id?.categoryId === item.id)
        .forEach((el) => (sumOfCategory += el?.calculatedEmission));
      return {
        ...item,
        calculateDate: sumOfCategory,
      };
    });
    return newData;
  } else if (scopeID === 3 && defaultScope) {
    const newData = Scope_3_Category.map((item) => {
      let sumOfCategory = 0;
      selectedScopeData
        .filter((id) => id?.categoryId === item.id)
        .forEach((el) => (sumOfCategory += el?.calculatedEmission));
      return {
        ...item,
        calculateDate: sumOfCategory,
      };
    });
    return newData;
  } else if (!defaultScope) {
    const newData = sourceValue.map((item) => {
      let sumOfCategory = 0;
      selectedScopeData
        .filter((id) => id?.categoryId === item.id)
        .forEach((el) => (sumOfCategory += el?.calculatedEmission));
      return {
        ...item,
        calculateDate: sumOfCategory,
      };
    });
    return newData;
  } else return [];
}
export const getCalculateDate = (data, scope) => {
  let sumOfCategory = 0;
  const selectedScopeData =
    Array.isArray(data) && scope
      ? data?.filter((scope) => scope.scopeId === scope)
      : Array.isArray(data)
      ? data
      : [];
  if (Array.isArray(selectedScopeData) && selectedScopeData.length > 0) {
    selectedScopeData?.forEach(
      (el) => (sumOfCategory += el?.calculatedEmission)
    );
    return sumOfCategory;
  } else return sumOfCategory;
};
