import React, { useEffect, useState } from "react";
import TitleDescriptionCard from "../../component/common/Cards/titleDescriptionCard";
import Calculation from "../../component/common/Cards/calculationCard";
import { useParams } from "react-router-dom";
import DateTimePicker from "react-date-picker";
import TableComponent from "../../component/common/Table/table";
import { useFetch } from "../../hooks/useFetch";
import { toast } from "react-toastify";

const initialData = {
  stream: "",
  category: "",
  activity: "",
  type: "",
  subType: "",
  vendor: "",
  isMonthly: true,
  date: "",
  unit: "",
  amountSpend: "",
};
function ScopeTreeIndividual() {
  const { method } = useParams();
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [allCalculatedData, setAllCalculatedData] = useState([]);
  const [calculateData, setCalculateData] = useState(initialData);

  const [streamData, setStreamData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [typeData, setTypeData] = useState([]);
  const [subType, setSubType] = useState([]);

  const HandelGetStream = async () => {
    const stream = await useFetch({
      url: process.env.REACT_APP_API_URL + "/api/SpendBased/stream/3",
      method: "GET",
    });
    setStreamData(stream);
  };

  const HandleSelectStream = async (id) => {
    setCalculateData({
      ...calculateData,
      stream: id,
    });
    const category = await useFetch({
      url: process.env.REACT_APP_API_URL + `/api/SpendBased/category/${id}`,
      method: "GET",
    });
    setCategoryData(category);
  };

  useEffect(() => {
    HandelGetStream();
  }, []);

  const HandleSelectCategory = async (category) => {
    setCalculateData({
      ...calculateData,
      category: categoryData.find((item) => item.id === Number(category)),
    });
    try {
      const activity = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/SpendBased/activity/${category}`,
        method: "GET",
      });
      setActivityData(activity);
    } catch (error) {
      console.log(error);
    }
  };
  const HandelSelectActivity = async (activity) => {
    setCalculateData({
      ...calculateData,
      activity: activityData.find((item) => item.id === Number(activity)),
    });
    try {
      const type = await useFetch({
        url: process.env.REACT_APP_API_URL + `/api/SpendBased/type/${activity}`,
        method: "GET",
      });
      setTypeData(type);
    } catch (error) {
      console.log(error);
    }
  };
  const HandelSelectType = async (type) => {
    setCalculateData({
      ...calculateData,
      type: typeData.find((item) => item.id === Number(type)),
    });
    try {
      const subType = await useFetch({
        url: process.env.REACT_APP_API_URL + `/api/SpendBased/subType/${type}`,
        method: "GET",
      });
      setSubType(subType);
    } catch (error) {
      console.log(error);
    }
  };

  const HandleAddData = async (event) => {
    event.preventDefault();
    const date = new Date();
    const modifyData = {
      ...calculateData,
      activity: calculateData?.activity ?? "",
      category: calculateData?.category ?? "",
      type: calculateData?.type ?? "",
      subType: calculateData?.subType ?? "",
      id: calculateData.id ?? date.getTime(),
    };
    if (calculateData?.id) {
      setAddCalculatedData(modifyData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      setCalculateData({
        ...calculateData,
        vendor: "",
        date: "",
        unit: "",
        amountSpend: "",
        id: null,
      });
      return;
    }

    let addId = { ...calculateData, id: date.getTime() };

    setAddCalculatedData(modifyData);
    setAllCalculatedData([...allCalculatedData, addId]);
    setCalculateData({
      ...calculateData,
      vendor: "",
      date: "",
      unit: "",
      amountSpend: "",
      id: null,
    });
  };
  const columns = [
    { key: "category", title: "Category" },
    { key: "activity", title: "Activity" },
    { key: "type", title: "Type" },
    { key: "subType", title: "Sub Type" },
    { key: "vendor", title: "Vendor" },
    { key: "isMonthly", title: "Is Monthly" },
    { key: "date", title: "Date" },
    { key: "unit", title: "Unit" },
    { key: "amountSpend", title: "AmountSpend" },
    { key: "delete", title: "Action" },
  ];
  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };

  const HandelCalculate = async () => {
    const body = allCalculatedData.map((itemData) => ({
      scopeId: 3,
      streamId: Number(itemData?.stream),
      categoryId: itemData.category.id,
      activityId: itemData.activity.id,
      typeId: itemData.type.id,
      subTypeId: itemData.subType.id,
      unit: itemData.unit,
      vendor: itemData.vendor,
      invTrnzDate: itemData.date,
      isMontlhlyData: itemData.isMonthly === "false" ? false : true,
      enteredValue: itemData.value,
      calcMethodId: 3,
    }));
    const res = await useFetch({
      url: process.env.REACT_APP_API_URL + `/api/SpendBased/scope3`,
      method: "POST",
      body: JSON.stringify(body),
    });
    if (res.status === 204) {
      toast.success("Your data has been successfully recorded!");
      allCalculatedData([]);
      setAddCalculatedData(null);
      setCalculateData();
    } else toast.error("An error occurred! Please try again later or contact the support team. ");
  };

  return (
    <>
      {" "}
      <div className="font-bold capitalize text-center text-xl xl:text-3xl w-full py-5 shadow-sm rounded-b-lg shadow-green-300 px-2">
        {`Scope 3  |  ${method.replaceAll("-", " ")}  |  Individual Records`}
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button
            className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold"
            onClick={HandelCalculate}
          >
            Calculate
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 bg-[#ffff] rounded-lg shadow-lg border-[1px]  p-5 items-center">
            <div>
              <label
                htmlFor="stream"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Stream
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.stream}
                onChange={(event) => HandleSelectStream(event.target.value)}
                required
              >
                <option value="">select</option>
                {streamData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Categories Selected (Class)
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.category.id}
                onChange={(event) => HandleSelectCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {categoryData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activity"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Activity
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.activity.id}
                onChange={(event) => HandelSelectActivity(event.target.value)}
                required
              >
                <option value="">select</option>
                {activityData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label
                htmlFor="type"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#4</span>Type
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.type.id}
                onChange={(event) => HandelSelectType(event.target.value)}
                required
              >
                <option value="">select</option>
                {typeData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="sub-type"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#5</span>Sub-Type
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.subType.id}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    subType: subType.find(
                      (item) => item.id === event.target.value
                    ),
                  })
                }
                required
              >
                <option value="">select</option>
                {subType.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="vendor"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#6</span>Vendor
              </label>
              <input
                type="text"
                id="vendor"
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[45px]"
                placeholder="Enter Vendor"
                value={calculateData.vendor}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    vendor: event.target.value,
                  })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#7</span>Date
              </label>
              <DateTimePicker
                required
                className="custom-calendar bg-green-50 py-2 w-full rounded-lg border-[1px] h-[45px]"
                dayPlaceholder="dd"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                calendarClassName=""
                view="month"
                onChange={(value) =>
                  setCalculateData({
                    ...calculateData,
                    date: value,
                  })
                }
                format={"dd/MM/yyyy"}
                value={calculateData.date}
                calendarIcon={
                  <img
                    src="/assets/calendar.png"
                    alt="calenderIcon"
                    width={15}
                    height={15}
                  />
                }
              />
            </div>
            <div>
              <label
                htmlFor="isMonth"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#8</span>Is Monthly
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.isMonthly}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    isMonthly: event.target.value,
                  })
                }
                required
              >
                <option value="">select</option>
                <option value={true}>True</option>
                <option value={false}>False</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="unit"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#9</span>Unit
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.unit}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    unit: event.target.value,
                  })
                }
                required
              >
                <option value="">select</option>
                <option value="rupees">₹ INR</option>
                <option value="dollar">$ USD</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="amount"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#10</span>Amount Spend
              </label>
              <input
                type="number"
                id="amount"
                value={calculateData.amountSpend}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[45px]"
                placeholder="Enter Amount"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    amountSpend: event.target.value,
                  })
                }
                required
              />
            </div>
            <div></div>
            <div></div>
            <div className="self-center">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      {addCalculatedData && (
        <div className="px-5 pb-5">
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        </div>
      )}
      <div className="mx-5 border-[1px] border-green-600 bg-white rounded-lg shadow-lg px-5">
        <TitleDescriptionCard
          data={titleDesc}
          type={"Upstream"}
          title={"Purchased Goods and Services:"}
          description={
            "Indirect emissions from the supply chain of materials and services purchased by the organization."
          }
        />
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default ScopeTreeIndividual;
const titleDesc = {
  rows: [
    {
      title: <p className="font-bold text-xl">More Details</p>,
      content: (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-4 mt-4">
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/PurchasedGoodsAndServices.png"
                alt="PurchasedGoodsAndServices"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Purchased Goods and Services{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Indirect emissions from the supply chain of materials and services
              purchased by the organization.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/CapitalGoods.png"
                alt="CapitalGoods"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Capital Goods{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions related to the production and acquisition of long-term
              assets like machinery or buildings.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/FuelAndEnergyRelatedActivities.png"
                alt="FuelAndEnergyRelatedActivities"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Fuel and Energy Related Activities{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions associated with the extraction, production, and
              transportation of fuels and energy sources used by the
              organization.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/Transprot&DIstribution.png"
                alt="Transprot&DIstribution"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Transportation and Distribution (T&D){" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions from the transportation and distribution of products or
              materials, including logistics and shipping.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/WasteGeneratedInOperations.png"
                alt="WasteGeneratedInOperations"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Waste Generated in Operations{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions from waste generated during the organization's
              operations, including disposal and treatment.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/BusinessTravel.png"
                alt="BusinessTravel"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Business Travel{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions from employee travel for work purposes, including
              flights, trains, and other transportation.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/EmloyeeCommuting.png"
                alt="EmloyeeCommuting"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Employee Commuting{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions from employee transportation to and from work, including
              personal vehicles, public transit, or biking.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/LeasedAssets.png"
                alt="LeasedAssets"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Leased Assets{" "}
                <span className="text-red-500 text-sm self-center">
                  Upstream
                </span>
              </p>
            </div>
            <p>
              Emissions associated with the use of assets leased by the
              organization, such as buildings or equipment.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/Transprot&DIstribution.png"
                alt="Transprot&DIstribution"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Transportation and Distribution (T&D){" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions from the transportation and distribution of products to
              customers or end-users
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/ProcessingOfSoldProducts.png"
                alt="ProcessingOfSoldProducts"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Precessing of Sold Products{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions from processes involved in preparing products for sale,
              such as packaging or assembly.
            </p>
          </div>

          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/UseOfSoldProducts.png"
                alt="UseOfSoldProducts"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Use of Sold Products{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions resulting from the use of products sold by the
              organization, including energy consumption or emissions during
              use.
            </p>
          </div>

          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/EndOfLife-treatment-of-soldProducts.png"
                alt="EndOfLife-treatment-of-soldProducts"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                End-of-Life Treatment of Sold Products{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions from the disposal or recycling of products at the end of
              their useful life.
            </p>
          </div>

          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/LeasedAssets.png"
                alt="LeasedAssets"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Leased Assets{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions associated with assets leased by customers or end-users,
              such as vehicles or equipment.
            </p>
          </div>

          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/Franchises.png"
                alt="Franchises"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Franchises{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions associated with the operation of franchises or licensed
              locations.
            </p>
          </div>

          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/Investments.png"
                alt="Investments"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Investments{" "}
                <span className="text-red-500 text-sm self-center">
                  Downstream
                </span>
              </p>
            </div>
            <p>
              Emissions associated with investments made by the organization,
              including financial instruments or holdings in other companies.
            </p>
          </div>
        </div>
      ),
    },
  ],
};
