import React, { useEffect, useState } from "react";
import ScopCard from "../../component/common/Cards/scopCard";
import Modal from "../../component/common/Modals/modal";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";

function EmissionCalculate() {
  const navigate = useNavigate();
  const [scopeArray, setScopeArray] = useState([]);
  const [activeMethods, setActiveMethods] = useState([]);
  const [modalOpen, setModalOpen] = useState(null);
  const [selectScope, setSelectScope] = useState();
  const [selectMethod, setSelectMethod] = useState();
  const [entryType, setEntryType] = useState();
  const [expandScopeData, setExpandScopeData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const showDetails = (title, desc) => {
    setModalOpen({
      show: true,
      title,
      desc,
    });
  };
  const CallScopeList = async () => {
    const scope = await useFetch({
      url: process.env.REACT_APP_API_URL + "/api/Scopes",
      method: "GET",
    });
    setScopeArray(scope);
  };
  useEffect(() => {
    CallScopeList();
  }, []);

  const HandelSelectScope = async (id) => {
    setIsLoading(true);
    setSelectScope(id);
    if (id === 2 || id === 3) {
      setEntryType("individual-records");
    }
    const methods = await useFetch({
      url: process.env.REACT_APP_API_URL + `/api/Scopes/method/${id}`,
      method: "GET",
    });
    setActiveMethods(methods);
    setSelectMethod("");
    setIsLoading(false);
  };

  const HandelNext = () => {
    if (entryType === "individual-records") {
      if (selectScope === 1) {
        navigate(`/scope-one-individual/${selectMethod}`);
      } else if (selectScope === 2) {
        navigate(`/scope-two-individual/${selectMethod}`);
      } else if (selectScope === 3) {
        navigate(`/scope-three-individual/${selectMethod}`);
      }
    } else {
      navigate(`/scope1/${entryType}/${selectMethod}`);
    }
  };
  return (
    <>
      <div className="font-bold capitalize text-center text-3xl w-full py-5 mb-4  shadow-sm rounded-b-lg shadow-green-300 px-2">
        Data accuracy fuels the sustainable journey towards{" "}
        <span className="text-green-500">Net-Zero Goals</span>
      </div>
      <div className="px-4 py-6">
        <div className="mb-5 flex justify-end">
          <button
            className="py-2 px-8 bg-green-500 text-white rounded-md hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold disabled:bg-gray-300 cursor-pointer"
            disabled={!selectScope || !selectMethod || !entryType}
            onClick={HandelNext}
          >
            Next
          </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-5">
          <div>
            <div className="text-lg font-bold my-2 ml-2 text-[#111827] max-w-[150px] w-full">
              Scope
            </div>
            <div className="flex items-center justify-between bg-green-100 py-2 px-6 rounded-lg">
              <select
                className="bg-transparent py-2  w-full rounded-lg"
                onChange={(e) => HandelSelectScope(Number(e.target.value))}
              >
                <option value="">Select</option>
                {scopeArray.map((scope, index) => (
                  <option key={index} value={scope.id}>
                    {scope.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-8">
              <div>
                <div className="mx-2 mb-2 gap-1 flex justify-start items-center">
                  <div className="font-bold">Direct Emissions</div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      showDetails(
                        "Direct Emissions",
                        "These are emissions directly produced by the company's activities or assets. Examples include exhaust emissions from company vehicles, emissions from on-site machinery or equipment, and emissions from industrial processes like manufacturing or refining."
                      )
                    }
                  >
                    <img
                      src={"/assets/modalIcon.svg"}
                      alt="modalIcon"
                      width={16}
                      height={12}
                    />
                  </div>
                </div>
                <ScopCard
                  isActive={selectScope === 1}
                  onClick={() => {
                    // HandelSelectScope(1);
                  }}
                  isReadMore={expandScopeData?.scope_1}
                  readMore={() =>
                    setExpandScopeData({
                      ...expandScopeData,
                      scope_1: !expandScopeData?.scope_1,
                    })
                  }
                  title={"Scope 1"}
                  desc={
                    "Refers to emissions that come directly from sources owned or controlled by the company. This includes emissions from burning fossil fuels for heating or powering equipment, emissions from on-site vehicles such as trucks or forklifts, and emissions from chemical processes."
                  }
                />
              </div>

              <div>
                <div className="mx-2 mb-2 gap-1 flex justify-start items-center">
                  <div className="font-bold">Indirect Emissions</div>
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      showDetails(
                        "Indirect Emissions",
                        "These emissions stem from sources external to the company but are associated with its operations. Examples include emissions from purchased electricity used in offices or manufacturing facilities, emissions from heating or cooling systems, and emissions from the disposal of waste generated by the company."
                      )
                    }
                  >
                    <img
                      src={"/assets/modalIcon.svg"}
                      alt="modalIcon"
                      width={16}
                      height={12}
                    />
                  </div>
                </div>
                <ScopCard
                  isActive={selectScope === 2}
                  onClick={() => {
                    // HandelSelectScope(2);
                  }}
                  isReadMore={expandScopeData?.scope_2}
                  readMore={() =>
                    setExpandScopeData({
                      ...expandScopeData,
                      scope_2: !expandScopeData?.scope_2,
                    })
                  }
                  title={"Scope 2"}
                  desc={
                    "These emissions stem from sources external to the company but are associated with its operations. Examples include emissions from purchased electricity used in offices or manufacturing facilities, emissions from heating or cooling systems, and emissions from the disposal of waste generated by the company."
                  }
                />
              </div>

              <ScopCard
                isActive={selectScope === 3}
                onClick={() => {
                  // HandelSelectScope(3);
                }}
                isReadMore={expandScopeData?.scope_3}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    scope_3: !expandScopeData?.scope_3,
                  })
                }
                title={"Scope 3"}
                desc={
                  " Includes a broader range of indirect emissions that occur throughout the company's value chain but are not directly owned or controlled by the company. Examples include emissions from the extraction, production, and transportation of raw materials used in manufacturing, emissions from employee commuting and business travel, emissions from the use and disposal of products sold by the company, and emissions associated with upstream and downstream activities in the supply chain."
                }
              />
            </div>
          </div>

          <div>
            <div className="text-lg font-bold my-2 ml-2 text-[#111827] w-full">
              Calculation Methodology
            </div>
            <div className="flex items-center justify-between bg-green-100 w-full py-2 px-6 rounded-lg">
              <select
                className="ml-2 bg-transparent py-2 w-full rounded-lg"
                onChange={(event) => {
                  setSelectMethod(event.target.value);
                }}
                value={selectMethod}
              >
                <option value="">Select</option>
                <option
                  value="average-data-method"
                  disabled={
                    activeMethods.some(
                      (item) => item.name !== "Average Method"
                    ) || activeMethods.length === 0
                  }
                >
                  Average Data Method{" "}
                </option>

                <option
                  value="spend-based-method"
                  disabled={
                    activeMethods.some(
                      (item) => item.name !== "Spend Based Method"
                    ) || activeMethods.length === 0
                  }
                >
                  Spend Based Method
                </option>

                <option
                  value="hybrid-method"
                  // disabled={activeMethods.some(
                  //   (item) => item.name !== "Hybrid Method"
                  // )}
                  disabled
                >
                  Hybrid Method (Coming soon..)
                </option>
                <option
                  value="supplier-specific-method"
                  // disabled={activeMethods.some(
                  //   (item) => item.name !== "Supplier Specific Method"
                  // )}
                  disabled
                >
                  Supplier Specific Method (Coming soon..)
                </option>
              </select>
            </div>
            <div className="grid grid-cols-1 gap-4 mt-8">
              <ScopCard
                isActive={selectMethod === "average-data-method"}
                isLoading={isLoading}
                onClick={() => {
                  // activeMethods.some(
                  //   (item) => item.name === "Average Method"
                  // ) && setSelectMethod("average-data-method");
                }}
                disable={activeMethods.some(
                  (item) => item.name !== "Average Method"
                )}
                isReadMore={expandScopeData?.average_data_method}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    average_data_method: !expandScopeData?.average_data_method,
                  })
                }
                title={"Average Data Method"}
                desc={
                  "This method utilizes industry-standard averages and benchmarks to estimate emissions. For instance, if a company knows its energy usage but lacks detailed emission data, it can use average emission factors specific to its industry to estimate emissions."
                }
              />
              <ScopCard
                isActive={selectMethod === "spend-based-method"}
                isLoading={isLoading}
                onClick={() => {
                  // activeMethods.some(
                  //   (item) => item.name === "Spend Based Method"
                  // ) && setSelectMethod("spend-based-method");
                }}
                isReadMore={expandScopeData?.spend_based_method}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    spend_based_method: !expandScopeData?.spend_based_method,
                  })
                }
                disable={activeMethods.some(
                  (item) => item.name !== "Spend Based Method"
                )}
                title={"Spend-Based Method"}
                desc={
                  "This approach calculates emissions based on the company's expenditures, such as energy or transportation costs. For example, if a company knows how much money it spends on electricity, it can use emission factors related to the electricity generation mix to estimate its emissions."
                }
              />
              <ScopCard
                isActive={selectMethod === "hybrid-method"}
                isLoading={isLoading}
                // onClick={() => setSelectMethod("hybrid-method")}
                isReadMore={expandScopeData?.hybrid_method}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    hybrid_method: !expandScopeData?.hybrid_method,
                  })
                }
                disable={activeMethods.some(
                  (item) => item.name !== "Hybrid Method"
                )}
                title={"Hybrid Method"}
                desc={
                  "This method combines different calculation approaches for improved accuracy. It may involve using a combination of average data, spend-based analysis, and direct measurement for various emission sources within the organization."
                }
              />
              <ScopCard
                isActive={selectMethod === "supplier-specific-method"}
                isLoading={isLoading}
                // onClick={() => setSelectMethod("supplier-specific-method")}
                disable={activeMethods.some(
                  (item) => item.name !== "Supplier Specific Method"
                )}
                isReadMore={expandScopeData?.supplier_specific_method}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    supplier_specific_method:
                      !expandScopeData?.supplier_specific_method,
                  })
                }
                title={"Supplier-Specific Method"}
                desc={
                  "This method involves gathering emissions data directly from suppliers to precisely quantify the company's total emissions footprint. For example, if a company procures goods or services from suppliers, it can request emissions data from those suppliers to understand the emissions associated with its supply chain activities more accurately."
                }
              />
            </div>
          </div>

          <div>
            <div className="font-bold my-2 ml-2 text-lg text-[#111827]">
              Entry Type
            </div>
            <div className="flex items-center justify-between bg-green-100 py-2 px-6 rounded-lg">
              <select
                className="ml-2 bg-transparent py-2 rounded-lg w-full"
                onChange={(event) => setEntryType(event.target.value)}
              >
                <option value="">Select</option>
                <option value="individual-records">Individual Records</option>
                <option
                  value="bulk-records"
                  disabled={selectScope === 3 || selectScope === 2}
                >
                  Bulk Records
                </option>
              </select>
            </div>

            <div className="grid grid-cols-1 gap-4 mt-8">
              <ScopCard
                isActive={entryType === "individual-records"}
                onClick={() => {
                  // setEntryType("individual-records")
                }}
                isReadMore={expandScopeData?.individual_records}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    individual_records: !expandScopeData?.individual_records,
                  })
                }
                title={"Individual Records"}
                desc={
                  "This option allows users to easily input specific data points, such as how much fuel their vehicles consume or how much energy their facilities use. It's like filling in simple forms where you just select the categories and enter the relevant numbers, making it straightforward and intuitive."
                }
              />

              <ScopCard
                isActive={entryType === "bulk-records"}
                // onClick={() =>
                //   selectScope !== 3 &&
                //   selectScope !== 2 &&
                //   setEntryType("bulk-records")
                // }
                disable={selectScope === 3 || selectScope === 2}
                isReadMore={expandScopeData?.bulk_records}
                readMore={() =>
                  setExpandScopeData({
                    ...expandScopeData,
                    bulk_records: !expandScopeData?.bulk_records,
                  })
                }
                title={"Bulk Records"}
                desc={
                  "With this option, users can gather all their data in one place, typically in a pre-defined spreadsheet template provided by us. Think of it as organizing your information neatly in a table, making it easy to manage and analyze in bulk. It's convenient for a huge number of records or for those who prefer to work offline with Excel sheets for storing the information."
                }
              />
            </div>
          </div>
        </div>
      </div>
      {modalOpen?.show && (
        <Modal
          isOpen={modalOpen?.show}
          onOustSideClick={() => setModalOpen(null)}
          icon={"/assets/modalIcon.svg"}
          title={modalOpen?.title}
        >
          {modalOpen?.desc}
        </Modal>
      )}
    </>
  );
}

export default EmissionCalculate;
