import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const scopeSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = scopeSlice.actions;

export default scopeSlice.reducer;
