import React from "react";
import ReactApexChart from "react-apexcharts";

function AreaChart() {
  const Series = [
    {
      name: "previous year",
      data: [31, 40, 28, 51, 42, 109, 100, 90, 87, 45, 70, 60],
    },
    {
      name: "current year",
      data: [11, 32, 45, 32, 34, 52, 41, 65, 74, 55, 74, 90],
    },
  ];

  const Options = {
    colors: ["#8472E1", "#A5F3FF", "#D0AEFF", "#3C98EF"],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0,
        opacityFrom: 0.7,
        opacityTo: 0.2,
      },
    },
    xaxis: {
      categories: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      tickPlacement: "on",
    },
    title: {
      text: "Emission Trend (Current Year Vs Last Year)",
      align: "left",
      margin: 50,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
  };

  return (
    <ReactApexChart
      type="area"
      series={Series}
      options={Options}
      height={400}
      width={"100%"}
    />
  );
}

export default AreaChart;
