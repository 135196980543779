import React from "react";

function DashBoardCard({ data, name, lastYearData }) {
  return (
    <div className="flex flex-col  bg-green-100 rounded-2xl border-[1px] shadow-xl">
      <div className="p-4">
        <div className="flex flex-col gap-2">
          <div className="w-full flex items-end gap-4 text-gray-800">
            <h1 className="font-bold text-[30px]">
              {(data / 1000)?.toFixed(2)}K<sup>#</sup>
            </h1>
            <p className="mb-0 font-semibold leading-normal text-2xl">
              tCO<sub>2</sub>e
            </p>
          </div>
          <div className="mt-4">
            <div className="flex justify-between items-end gap-2">
              <div>
                <h5 className="font-bold text-[20px] text-gray-800">{name}</h5>
                <div className="block font-semibold">#Kt- kilo-tonnes</div>
              </div>
              <div className=" w-[80px] h-[60px] text-center rounded-xl bg-gradient-to-tl from-blue-500 to-violet-500 p-1">
                <div className="text-lg text-white font-semibold">
                  {(lastYearData / 1000).toFixed(2) ?? 0}Kt
                </div>
                <p className="text-md text-white">last year</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoardCard;
