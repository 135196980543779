import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { getCategoryData } from "../../../utils/getcategory";

function BarChart({
  height,
  scope,
  summaryData,
  defaultScope = true,
  title,
  type,
}) {
  const [category, setCategory] = useState([]);

  useEffect(() => {
    const getDataCategory = () => {
      if (scope === "Scope 3") {
        const getCateData = getCategoryData({
          scopeID: 3,
          dataValue:
            summaryData?.scope3AverageEmissionsSummaries ?? summaryData ?? [],
          defaultScope,
        });
        setCategory(getCateData);
      } else if (scope === "Scope 2") {
        const getCateData = getCategoryData({
          scopeID: 2,
          dataValue:
            summaryData?.scope2AverageEmissionsSummaries ?? summaryData ?? [],
          defaultScope,
        });
        setCategory(getCateData);
      } else {
        const getCateData = getCategoryData({
          scopeID: 1,
          dataValue:
            summaryData?.scope1AverageEmissionsSummaries ?? summaryData ?? [],
          defaultScope,
        });
        setCategory(getCateData);
      }
    };
    getDataCategory();
  }, [defaultScope, scope, summaryData]);

  const barData = [
    {
      data: category?.map((data) => ({
        x: data.text,
        y: data?.calculateDate ?? 0,
      })),
    },
  ];

  const barOptions = {
    labels: category?.map((data) => data.text),
    chart: {
      foreColor: "#999999",
      toolbar: {
        show: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "20%",
        endingShape: "rounded",
        borderRadius: 12,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    legend: {
      width: "100%",
      position: "top",
      horizontalAlign: "right",
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: "#fff",
        fillColors: ["#9E86FF"],
        radius: 12,
        offsetX: 0,
        offsetY: 2,
      },
    },
    xaxis: {
      categories: category?.map((data) => data.text),
    },
    fill: {
      opacity: 1,
      type: "gradient",
      colors: ["#9E86FF"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
      style: {
        fontSize: "14px",
      },
      y: {
        formatter: function (val) {
          return val.toFixed(2);
        },
      },
    },
    title: {
      text: title,
      align: "left",
      margin: 20,
      offsetX: 10,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "18px",
        fontWeight: "bold",
        fontFamily: "Helvetica, Arial, sans-serif",
        color: "#263238",
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      type="bar"
      series={barData}
      options={barOptions}
      height={height ?? 300}
      width={"100%"}
    />
  );
}

export default BarChart;
