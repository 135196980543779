import React from "react";
import ReactApexChart from "react-apexcharts";

function PieChart({ data1, data2, data3 }) {
  const CHART_DATA = [data1 ?? 0, data2 ?? 0, data3 ?? 0];
  const PieOptions = {
    labels: ["Total Emission", "Reduction 1", "Reduction 2"],
    legend: {
      position: "bottom",
      itemMargin: { vertical: 8 },
    },
    colors: ["#8472E1", "#A5F3FF", "#D0AEFF", "#3C98EF"],
    chart: {
      events: {
        dataPointSelection: (event, chartContext, config) => {
          console.log(config.w.config);
          console.log(config.w.config.series[config.dataPointIndex]);
        },
      },
    },
    stroke: {
      //   curve: "smooth",
      // OR provide an array
      curve: ["straight", "smooth", "monotoneCubic", "stepline"],
    },
    dataLabels: {
      position: "top",
      enabled: true,
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: true } } },
    },
    tooltip: {
      enabled: true,
    },
    title: {
      text: "EMISSION VS REDUCTION",
      align: "center",
      margin: 50,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
  };
  return (
    <ReactApexChart
      type="pie"
      series={CHART_DATA}
      options={PieOptions}
      height={400}
    />
  );
}

export default PieChart;
