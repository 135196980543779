import React, { useEffect, useState } from "react";
import DateTimePicker from "react-date-picker";
import { useParams } from "react-router-dom";
import Calculation from "../../component/common/Cards/calculationCard";
import TitleDescriptionCard from "../../component/common/Cards/titleDescriptionCard";
import TableComponent from "../../component/common/Table/table";
import { useFetch } from "../../hooks/useFetch";
const initialData = {
  category: "",
  activity: "",
  region: "",
  vendor: "",
  date: new Date(),
  unit: "kWh",
  value: "",
};
function ScopeTwoIndividual() {
  const { method } = useParams();
  const [classCategory, setClassCategory] = useState([]);
  const [activity, setActivity] = useState([]);
  const [region, setRegion] = useState([]);
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [calculateData, setCalculateData] = useState(initialData);
  const [allCalculatedData, setAllCalculatedData] = useState([]);
  console.log("calculateData", calculateData);
  const HandleSelectCategory = async (category) => {
    setCalculateData({
      ...calculateData,
      category: classCategory.find((item) => item.id === Number(category)),
    });
    try {
      const ActivityData = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/AverageMethod/activity/${category}`,
        method: "GET",
      });
      setActivity(ActivityData);
    } catch (error) {
      console.log(error);
    }
  };

  const HandleSelectActivity = async (activityItem) => {
    setCalculateData({
      ...calculateData,
      activity: activity.find((item) => item.id === Number(activityItem)),
    });
    try {
      const ActivityCategoryData = await useFetch({
        url:
          calculateData?.category?.id === 4
            ? process.env.REACT_APP_API_URL +
              `/api/AverageMethod/category/${calculateData.category.id}?activityId=${activityItem}`
            : calculateData?.category?.id === 5
            ? process.env.REACT_APP_API_URL +
              `/api/AverageMethod/Scope2/IndiaElectricity/${activityItem}`
            : process.env.REACT_APP_API_URL +
              `/api/AverageMethod/Scope2/OverseaElectricity/${activityItem}`,
        method: "GET",
      });
      setRegion(ActivityCategoryData);
    } catch (error) {
      console.log(error);
    }
  };

  const HandleAddData = async (event) => {
    event.preventDefault();
    if (calculateData?.id) {
      setAddCalculatedData(calculateData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      setCalculateData({
        ...calculateData,
        vendor: "",
        date: new Date(),
        unit: "kWh",
        value: "",
        id: null,
      });
      return;
    }
    const date = new Date();
    let addId = { ...calculateData, id: date.getTime() };
    setAddCalculatedData(addId);
    setAllCalculatedData([...allCalculatedData, addId]);
    setCalculateData({
      ...calculateData,
      vendor: "",
      date: new Date(),
      unit: "kWh",
      value: "",
      id: null,
    });
  };

  const columns = [
    { key: "category", title: "Category" },
    { key: "activity", title: "Activity" },
    { key: "region", title: "Region" },
    { key: "vendor", title: "Vendor" },
    { key: "date", title: "Date" },
    { key: "unit", title: "Unit" },
    { key: "value", title: "Value" },
    { key: "delete", title: "Action" },
  ];
  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };

  const HandelAvgClass = async () => {
    const categoryClass = await useFetch({
      url: process.env.REACT_APP_API_URL + "/api/AverageMethod/class/2",
      method: "GET",
    });
    setClassCategory(categoryClass);
  };

  useEffect(() => {
    if (method === "average-data-method") {
      HandelAvgClass();
    }
  }, [method]);
  return (
    <>
      {" "}
      <div className="font-bold capitalize text-center text-xl lg:text-3xl w-full py-5 shadow-sm rounded-b-lg shadow-green-300 px-2">
        {`Scope 2 | ${method.replaceAll("-", " ")} | Individual Records`}
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold">
            Calculate
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 bg-white rounded-lg border-[1px] shadow-lg p-5 items-center">
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Categories
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData.category.id}
                onChange={(event) => HandleSelectCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {classCategory?.map((item, index) => (
                  <option key={index} value={item?.id}>
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activty"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Activity
              </label>
              <select
                className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                value={calculateData?.activity?.id}
                onChange={(event) => HandleSelectActivity(event.target.value)}
                required
              >
                <option value="">select</option>
                {activity.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {calculateData?.category?.id !== 5 && (
              <div>
                <label
                  htmlFor="region"
                  className="block mb-2 text-base font-semibold text-gray-900"
                >
                  <span className="pr-1">#2.1</span>Region
                </label>
                <select
                  className="bg-green-50 py-2  w-full rounded-lg border-[1px] h-[45px]"
                  value={calculateData.region.id}
                  onChange={(event) =>
                    setCalculateData({
                      ...calculateData,
                      region: region.find(
                        (region) => region.id === Number(event.target.value)
                      ),
                      unit: region.find(
                        (region) => region.id === Number(event.target.value)
                      )?.unit,
                    })
                  }
                  required
                >
                  <option value="">select</option>
                  {region.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.country}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div>
              <label
                htmlFor="vendor"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Vendor
              </label>
              <input
                type="text"
                id="vendor"
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[45px]"
                placeholder="Enter Vendor"
                value={calculateData.vendor}
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    vendor: event.target.value,
                  })
                }
                required
              />
            </div>

            <div>
              <label
                htmlFor="date"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#4</span>Date
              </label>
              <DateTimePicker
                className="custom-calendar bg-green-50 py-2 w-full rounded-lg border-[1px] h-[45px]"
                dayPlaceholder="dd"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                calendarClassName=""
                view="month"
                onChange={(value) =>
                  setCalculateData({
                    ...calculateData,
                    date: value,
                  })
                }
                format={"dd/MM/yyyy"}
                value={calculateData.date}
                calendarIcon={
                  <img
                    src="/assets/calendar.png"
                    alt="calenderIcon"
                    width={15}
                    height={15}
                  />
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="unit"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#5</span>Unit
              </label>
              <input
                type="text"
                id="unit"
                value={calculateData.unit}
                readOnly
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[45px]"
                placeholder="Enter Unit"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    unit: event.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label
                htmlFor="value"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#6</span>Enter value
              </label>
              <input
                type="number"
                id="value"
                value={calculateData.value}
                className="bg-green-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[45px]"
                placeholder="Enter Date"
                onChange={(event) =>
                  setCalculateData({
                    ...calculateData,
                    value: event.target.value,
                  })
                }
                required
              />
            </div>
            {calculateData?.category?.id !== 5 && <div></div>}
            {calculateData?.category?.id !== 5 && <div></div>}
            <div className="self-center">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-5 pb-5">
        {addCalculatedData && (
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        )}
      </div>
      <div className="mx-5 border-[1px] border-green-600 bg-white rounded-lg shadow-lg px-5">
        <TitleDescriptionCard data={titleDesc} />
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default ScopeTwoIndividual;
const titleDesc = {
  rows: [
    {
      title: <p className="font-bold text-xl">More Details</p>,
      content: (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 pb-4 mt-4">
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/PurchasedElectricity.png"
                alt="PurchasedElectricity"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">Purchased Electricity</p>
            </div>
            <p>
              Indirect emissions from the generation of electricity purchased
              and used by the organization.
            </p>
          </div>
          <div className=" bg-transparent border-[1px] border-green-400 rounded-lg  px-4 py-2">
            <div className="flex gap-2 items-center pb-2">
              <img
                src="/assets/PurchasedHeating.png"
                alt="PurchasedHeating"
                width={24}
                height={24}
              />
              <p className="font-semibold text-lg">
                Purchased Steam, Heat, and Cooling
              </p>
            </div>
            <p>
              Indirect emissions associated with the purchase and use of steam,
              heat, and cooling from external sources.
            </p>
          </div>
        </div>
      ),
    },
  ],
};
