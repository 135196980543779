import React, { useEffect, useState } from "react";
import * as xlsx from "xlsx";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { useParams } from "react-router-dom";
import Calculation from "../../component/common/Cards/calculationCard";
import { Renderer } from "xlsx-renderer";
import { saveAs } from "file-saver";
import { useFetch } from "../../hooks/useFetch";
import TableComponent from "../../component/common/Table/table";
const initialData = {
  categorySelected: "",
  activity: "",
  category: "",
  subActivity: "",
  vendor: "",
  isMonthly: true,
  date: new Date(),
  unit: "",
  value: "",
};
function AllScopeBulk() {
  const { method, scope } = useParams();
  const [addCalculatedData, setAddCalculatedData] = useState();
  const [classCategory, setClassCategory] = useState([]);
  const [activity, setActivity] = useState([]);
  const [activityCategory, setActivityCategory] = useState([]);
  const [subActivityCategory, setSubActivityCategory] = useState([]);

  const HandelCategoryClass = async () => {
    const categoryClass = await useFetch({
      url: process.env.REACT_APP_API_URL + "/api/AverageMethod/class/1",
      method: "GET",
    });
    setClassCategory(categoryClass);
  };

  const columns = [
    { key: "categorySelected", title: "Category(Class)" },
    { key: "activity", title: "Activity" },
    { key: "category", title: "Category" },
    { key: "subActivity", title: "Sub Activity" },
  ];
  useEffect(() => {
    HandelCategoryClass();
  }, []);
  const [calculateData, setCalculateData] = useState(initialData);
  const [allCalculatedData, setAllCalculatedData] = useState([]);
  const HandleSelectCategory = async (category) => {
    setCalculateData({
      ...calculateData,
      categorySelected: classCategory.find(
        (item) => item.id === Number(category)
      ),
    });
    try {
      const ActivityData = await useFetch({
        url:
          process.env.REACT_APP_API_URL +
          `/api/AverageMethod/activity/${category}`,
        method: "GET",
      });
      setActivity(ActivityData);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleSelectActivity = async (activityItem) => {
    setCalculateData({
      ...calculateData,
      activity: activity.find((item) => item.id === Number(activityItem)),
    });
    try {
      const ActivityCategoryData = await useFetch({
        url:
          process.env.REACT_APP_API_URL + ((calculateData.categorySelected.id ===
          3)
            ? `/api/AverageMethod/Scope1/vehicleType/${activityItem}`
            : `/api/AverageMethod/category/${calculateData.categorySelected.id}?activityId=${activityItem}`),
        method: "GET",
      });
      setActivityCategory(ActivityCategoryData);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleActivityCategory = async (ActivityCategory) => {
    setCalculateData({
      ...calculateData,
      category: activityCategory.find(
        (item) => item.id === Number(ActivityCategory)
      ),
    });
    try {
      const vehicleFuel = await useFetch({
        url: process.env.REACT_APP_API_URL + `/api/AverageMethod/Scope1/vehicleFuel`,
        method: "GET",
      });
      setSubActivityCategory(vehicleFuel);
    } catch (error) {
      console.log(error);
    }
  };
  const HandleDownloadTemplate = async () => {
    const viewModel = {
      scope: "scope-1",
      method: "Supplier Specific",
      projects: allCalculatedData.map((data, index) => {
        return {
          name: `${index + 1}`,
          role: `${index + 1}_${data.categorySelected.name[0]}_${
            data.activity.name[0]
          }_${data.category.name[0]}`,
          link: `#'${index + 1}_${data.categorySelected.name[0]}_${
            data.activity.name[0]
          }_${data.category[0]}'!A1`,
          description: `${data.categorySelected.name}_${data.activity.name}_${data.category.name}`,
        };
      }),
    };
    const renderer = new Renderer();

    fetch("https://cct-web-carbmetrics.s3.ap-south-1.amazonaws.com/test.xlsx")
      .then((response) => response.arrayBuffer())
      .then((buffer) => renderer.renderFromArrayBuffer(buffer, viewModel))
      .then((report) => {
        const mainWorkSheet = report.xlsx.workbook.getWorksheet("Description");
        mainWorkSheet.views = [
          {
            state: "frozen",
            ySplit: 4,
          },
        ];
        allCalculatedData.forEach((item, index) => {
          mainWorkSheet.getCell(`C${index + 5}`).value = {
            text: `${index + 1}_${item.categorySelected.name[0]}_${
              item.activity.name[0]
            }_${item.category.name[0]}`,
            hyperlink: `#'${index + 1}_${item.categorySelected.name[0]}_${
              item.activity.name[0]
            }_${item.category.name[0]}'!C${index + 5}`,
          };

          const sheetName = `${index + 1}_${item.categorySelected.name[0]}_${
            item.activity.name[0]
          }_${item.category.name[0]}`;
          report.xlsx.workbook.addWorksheet(sheetName, {
            properties: { tabColor: { argb: "FFC0000" } },
          });
          const worksheet = report.xlsx.workbook.getWorksheet(sheetName);
          worksheet.views = [
            {
              state: "frozen",
              ySplit: 4,
            },
          ];
          worksheet.getColumn("A").width = 20;
          worksheet.getColumn("B").width = 20;
          worksheet.getColumn("C").width = 20;
          worksheet.getColumn("D").width = 20;

          const cellA1 = worksheet.getCell("A1");
          const cellB1 = worksheet.getCell("B1");
          const cellC1 = worksheet.getCell("C1");
          const cellD1 = worksheet.getCell("D1");
          const headerRow = worksheet.getRow(4);
          Object.keys(item)
            .filter(
              (fil) =>
                fil !== "id" &&
                fil !== "categorySelected" &&
                fil !== "activity" &&
                fil !== "category" &&
                fil !== "subActivity"
            )
            .forEach((colValue, index) => {
              headerRow.getCell(index + 1).value = colValue;
              headerRow.getCell(index + 1).border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              headerRow.getCell(index + 1).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "FFFF00" },
              };
            });
          worksheet.dataValidations.model["B5:B9999"] = {
            allowBlank: true,
            error: "Please use the drop down to select a valid value",
            errorTitle: "Invalid Selection",
            formulae: ['"true,false"'],
            showErrorMessage: true,
            type: "list",
          };
          worksheet.dataValidations.model["C5:C9999"] = {
            type: "date",
            showErrorMessage: true,
            allowBlank: true,
            formulae: [new Date()],
          };

          cellA1.value = item.categorySelected.name;
          cellB1.value = item.activity.name;
          cellC1.value = item.category.name;
          cellD1.value = item.subActivity.name;

          cellA1.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          cellB1.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          cellC1.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
          cellD1.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" },
          };
        });
        return report.xlsx.writeBuffer();
      })
      .then((buffer) => saveAs(new Blob([buffer]), `${Date.now()}_report.xlsx`))
      .catch((err) => console.log("Error writing excel export", err));
  };

  const HandleUploadExcel = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        console.log(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const HandleAddData = async (event) => {
    event.preventDefault();
    const date = new Date();
    const modifyData = {
      ...calculateData,
      activity: calculateData?.activity ?? "",
      category: calculateData?.category ?? "",
      categorySelected: calculateData?.categorySelected ?? "",
      subActivity: calculateData?.subActivity ?? "",
      id: calculateData.id ?? date.getTime(),
    };
    if (calculateData?.id) {
      setAddCalculatedData(modifyData);
      const findIdData = allCalculatedData.findIndex(
        (item) => item.id === calculateData?.id
      );
      let UpdateData = [...allCalculatedData];
      UpdateData[findIdData] = calculateData;
      setAllCalculatedData(UpdateData);
      return;
    }

    console.log("calculateData", calculateData);
    let addId = { ...calculateData, id: date.getTime() };

    setAddCalculatedData(modifyData);
    setAllCalculatedData([...allCalculatedData, addId]);
  };

  const HandleDeleteItem = (id) => {
    const updateData = allCalculatedData.filter((item) => item.id !== id);
    if (addCalculatedData?.id === id) {
      setAddCalculatedData(null);
    }
    setAllCalculatedData(updateData);
  };
  return (
    <>
      <div className="font-bold capitalize text-center text-xl lg:text-3xl w-full py-5   shadow-sm rounded-b-lg shadow-green-300 px-2">
        {`${scope} | ${method.replaceAll("-", " ")} | Bulk Upload`}
      </div>
      <div className="p-5 flex flex-col gap-6">
        <div className="flex justify-end items-center">
          <button className="px-5 py-2 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold">
            Data Review
          </button>
        </div>
        <form onSubmit={HandleAddData}>
          <div className="grid grid-cols-3 gap-5 bg-white rounded-lg shadow-lg border-[1px]  p-5">
            <div>
              <label
                htmlFor="category"
                className="block mb-2 text-base font-semibold text-gray-900"
              >
                <span className="pr-1">#1</span>Category Selected (Class)
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px]"
                value={calculateData?.categorySelected.id}
                onChange={(event) => HandleSelectCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {classCategory.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activity"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#2</span>Activity
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px]"
                value={calculateData?.activity.id}
                onChange={(event) => HandleSelectActivity(event.target.value)}
                required
              >
                <option value="">select</option>
                {activity.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label
                htmlFor="activityCategory"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                <span className="pr-1">#3</span>Category
              </label>
              <select
                className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px]"
                value={calculateData?.category.id}
                onChange={(event) => HandleActivityCategory(event.target.value)}
                required
              >
                <option value="">select</option>
                {activityCategory.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {calculateData.categorySelected.id === 3 && (
              <div>
                <label
                  htmlFor="activityCategory"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  <span className="pr-1">#4</span>Sub Activity
                </label>
                <select
                  className="bg-green-50 py-2 px-2 w-full rounded-lg border-[1px]"
                  value={calculateData?.subActivity.id}
                  onChange={(event) =>
                    setCalculateData({
                      ...calculateData,
                      subActivity: subActivityCategory.find(
                        (fil) => fil.id === Number(event.target.value)
                      ),
                    })
                  }
                  required
                >
                  <option value="">select</option>
                  {subActivityCategory.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {calculateData.categorySelected.id === 3 && <div></div>}
            {calculateData.categorySelected.id === 3 && <div></div>}

            <div className="self-end">
              <button
                type="submit"
                className="py-1 px-6 bg-green-500 text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg  font-semibold"
              >
                {calculateData?.id ? "Update" : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="px-5 pb-5">
        {addCalculatedData && (
          <Calculation
            CalculateData={addCalculatedData}
            setCalculateData={setCalculateData}
          />
        )}
      </div>

      <div className="flex justify-between items-center mx-5">
        <div className="flex justify-center mb-4 hover:shadow-lg transition ease-in-out duration-300 rounded-lg">
          <label
            className="flex justify-center items-center gap-2 cursor-pointer bg-green-500 rounded-l px-5 py-2 text-white focus:outline-none text-lg lg:text-xl font-semibold"
            htmlFor="inputGroupFile"
          >
            <img
              src="/assets/uploadFileIcon.png"
              alt="uploadFileIcon"
              width={18}
              height={18}
            />
            Browse
          </label>
          <input
            type="file"
            className="!border-gray-400 hover:!border-green-500 cursor-pointer border-[1px] rounded-r-lg form-control w-full px-3 py-2 text-sm text-gray-900"
            id="inputGroupFile"
            accept=".xlsx"
            onChange={HandleUploadExcel}
          />
        </div>
        <button
          className="px-5 py-2 gap-1 rounded-l-lg bg-green-500 flex items-center justify-center text-white rounded-lg hover:shadow-lg transition ease-in-out duration-300 text-lg lg:text-xl font-semibold"
          onClick={HandleDownloadTemplate}
        >
          <img
            src="/assets/excelIcon.png"
            alt="excelIcon"
            width={24}
            height={24}
          />
          <span>Download Template</span>
        </button>
      </div>
      <div className="mt-6 px-5 pb-5">
        {Array.isArray(allCalculatedData) && allCalculatedData.length > 0 && (
          <TableComponent
            columns={columns}
            rowData={allCalculatedData}
            onDelete={HandleDeleteItem}
          />
        )}
      </div>
    </>
  );
}

export default AllScopeBulk;
