import cookie from "cookiejs";
export const useFetch = ({ url, method, body }) => {
  // when Auth0 validation perfectly uncomment all commented code
  // const { loginWithRedirect } = useAuth0();
  return new Promise((resolve, reject) => {
    const Token = cookie.get("access_token");
    if (Token) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("access-control-allow-origin", "*");
      myHeaders.append("Authorization", `Bearer ${Token}`);

      const requestOptions = {
        method: method,
        headers: myHeaders,
        body: body,
        redirect: "follow",
      };
      if (method === "POST") {
        fetch(url, requestOptions)
          .then((respData) => {
            if (respData) {
              resolve(respData);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        fetch(url, requestOptions)
          .then((response) => response?.json())
          .then((respData) => {
            if (respData) {
              resolve(respData);
            }
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      }
    }
  });
};
